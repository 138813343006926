import styled, { css } from 'styled-components'

interface ISetOut {
  visible: boolean
}

export const Container = styled.div<ISetOut>`
  position: fixed;
  z-index: 10011;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #f6f7f9;
  opacity: 0.5;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  -webkit-animation: fade 0.3s forwards;
  -moz-animation: fade 0.3s forwards;
  animation: fade 0.3s forwards;

  @-webkit-keyframes fade {
    to {
      opacity: 1;
    }
  }

  @-moz-keyframes fade {
    to {
      opacity: 1;
    }
  }

  @keyframes fade {
    to {
      opacity: 1;
    }
  }

  ${({ visible }) =>
    visible &&
    css`
      display: flex;
    `}

  ${({ visible }) =>
    !visible &&
    css`
      display: none;
    `}
`

export const Animation = styled.div`
  display: flex;
  /* height: 58px; */
  margin-bottom: 30px;

  justify-content: center;
  align-items: center;
  svg {
    width: 250px;
    height: 40px;
  }
`
