export class NewURL {
  private _URL: string

  constructor(URL: string, baseURL?: string) {
    if (baseURL) {
      this._URL = `${baseURL}${URL}`
    } else {
      this._URL = URL
    }
  }

  setQueryParams(name: string, value: string): this {
    if (/[?]/.test(this._URL)) {
      this._URL = `${this._URL}&${name}=${encodeURIComponent(value)}`
    } else {
      this._URL = `${this._URL}?${name}=${encodeURIComponent(value)}`
    }

    return this
  }

  toString(): string {
    return this._URL
  }
}
