import styled from 'styled-components'

export const UserSidebarContainer = styled.div`
	width: 350px;
    height: 100%;
	position: relative;
	display: flex;
    flex-direction: column;
    z-index: 9999;

  @media(max-width: 414px){
    width: 100%;
  }
`

export const UserSidebarInfo = styled.div`
	display: inline-block;
	width: 100%;
	padding: 0 50px;
	margin-bottom: 40px;
`

export const UserSidebarAvatar = styled.div`
	& div {
	margin: 0px;
    width: 85px;
    height: 85px;
    font: normal 25px 'Inter';
	}
`

export const UserSidebarHeader = styled.div`
	text-align: center;
	margin-bottom: 32px;
	display: flex;
	flex-direction: column;
	align-items: center;

	& h4 {
		font: 600 20px sans-serif;
        font-family: 'Inter';
		color: #2e384d;
		margin-top: 12px;
	}

	& span {
		font-size: 14px;
		color: #2e384d;
	}

	& img {
		width: 85px;
		height: 85px;
		border-radius: 100%;
		margin-bottom: 8px;
	}
`
export const CloseButton = styled.button`
	border: 0;
	background: none;
	position: absolute;
	right: 16px;
	top: 16px;
	padding: 0;
	cursor: pointer;

	&:focus {
		outline: none;
	}
`

export const IconContainer = styled.div`
	display: inline-block;
	width: 18px;
	margin-right: 16px;
	vertical-align: middle;

	#icon-edit {
		path {
			fill: #a7abb2;
		}
	}

	#logout {
		path {
			stroke: ${({ theme }) => theme.mainGray};
		}
	}
`

export const UserSidebarActions = styled.ul`
	border-bottom: 1px solid #dfe3e8;

  @media(max-width: 414px){
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
  }
	& a {
		display: inline-block;
		width: 100%;
		padding: 23px 32px;
		border-top: 1px solid #dfe3e8;
		font-size: 14px;
		color: #6b7786;
		line-height: 15px;
		text-decoration: none;
        transition: .5s;
		cursor: pointer ;
		span {
			display: inline-block;
			vertical-align: middle;
		}
	}

	& a:hover {
		background: #f3f5f9;
		color: #378ff7;

		#icon-import{
			svg * {
				stroke: #378ff7;
			}
		}

		#icon-edit{
			path {
				fill: #378ff7;
			}
		}

		#logout {
			path {
				stroke: #378ff7;
			}
		}
	}

  .disabled{
    background-color: #F3F5F9;
    color: #2E384D;

    :hover{
      cursor: default;
      background-color: #F3F5F9;
      color: #2E384D;
    }    
  }
`

export const SelectContainer = styled.div`
  >label{
    font-size: 13px;
    font-family: 'Inter';
    color: black;
    font-weight: 600;
  }
  >div{
    margin-top: 7px;
  }
  div{
    background-color: #f3f5f9;
    text-align: center;
  }
  input{
    text-align: center;
    background-color: #f3f5f9;
  }
`

export const CheckboxContainer = styled.div`
	margin: 8px 0 24px 0;
`
