import styled, { css } from 'styled-components'

export const Container = styled.footer<{ isFocused: boolean }>`
  ${({ isFocused }) =>
    isFocused &&
    css`
      display: none;
    `}

  ${({ isFocused }) =>
    !isFocused &&
    css`
      display: block;
      position: relative;
      color: #dfe3e8;
      text-align: center;
      width: 90%;
      margin: 2.5vw;
    `}

  p {
    font-size: 12px;
  }

  @media (max-width: 414px) {
  }
  address {
    line-height: 20px;
    margin-bottom: 20px;
  }
  a {
    color: #62b8ff;
    text-decoration: none;
  }

  @media (max-width: 700px) {
    bottom: 22px;
    p {
      font-size: 14px;
    }
  }
`

export const SupportFooter = styled.div`
  position: relative;
  bottom: 48px;
  text-align: center;

  > p {
    display: flex;
    justify-content: center;

    a {
      margin-left: 3px;
    }

    > span {
      height: 22px;
      line-height: 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 10px;
      + span {
        border-left: 1px solid white;
        cursor: pointer;
        transition: all 0.5s;
        :hover {
          filter: brightness(120%);
        }
      }
      svg {
        margin-right: 3px;
      }
    }
  }

  > p + p {
    margin-top: 4px;
  }

  @media (max-width: 700px) {
    bottom: 41px;

    > p {
      width: fit-content;
      height: 20px;
      margin: 0 auto;
      svg {
        margin-right: 4px;
      }
      align-items: center;
    }
  }
`
