import React, { FC } from 'react'
import { Route, Switch } from 'react-router-dom'
import { RouteWrapper, PlatformOrLogin } from './Route'
import { Security } from '../pages/Security'
import { Login } from '../pages/Login/Login'
import Hstory from '../pages/HStory'
import guardHstory from '../modules/resolvers/HStoryGuard'

export const Routes: FC = () => {
  return (
    <Switch>
      <RouteWrapper protectedRoute path="/seguranca" component={Security} />

      <Route exact path="/login" component={Login} />
      <RouteWrapper guard={guardHstory} protectedRoute path="/dashboard" component={Hstory} />

      <Route exact path="*" component={PlatformOrLogin} />
    </Switch>
  )
}
