export const messages = {
  profileEdit: 'Informações do Perfil',
  seurityData: 'Dados de Segurança',
  dataProfile: 'DADOS PESSOAIS',
  fullName: 'Nome Completo',
  userName: 'Nome de usuário',
  email: 'E-mail',
  CPF: 'CPF',
  phone: 'Telefone',
  professionalData: 'dados profissionais',
  profile: 'Perfil',
  classCode: 'Código de Classe',
  save: 'Salvar',
  editPassword: 'Alterar Senha',
  currentPassword: 'Senha atual',
  passwordMustBe: 'Sua senha deve conter:',
  passwordMustBe_characters: 'Pelo menos 8 caracteres',
  passwordMustBe_uppercase: 'Letra maiúscula (A-Z)',
  passwordMustBe_lowercase: 'Letra minúscula (a-z)',
  passwordMustBe_number: 'Número (0-9)',
  passwordMustBe_specialCharacters: 'Caractere especial (Ex.: @#$&*)',
  newPassword: 'Nova Senha',
  confirmNewPassword: 'Confirmar nova senha',
  confirmPasswordValidationError: 'As senhas não coincidem, digite novamente',
  updatePasswordError: 'Erro ao atualizar a senha, tente novamente. Caso persista, contate o suporte.',
  updatePasswordSuccess: 'Informações atualizadas com sucesso!',
  updatePlatformSuccess: 'Plataforma atualizada com sucesso!',
  openSignatureModal: 'Visualizar Assinatura',
  updatePerfilError: 'Erro ao atualizar as informações de perfil, tente novamente. Caso persista, contate o suporte.',
  updatePerfilEmailError: 'O e-mail cadastrado já está em uso, tente novamente com outro e-mail.',
  updatePerfilSuccess: 'Informações de perfil atualizadas com sucesso!',
  incompletedPhone: 'Número de telefone incompleto.',
  signature: 'Assinatura',
  addPhoto: 'Inserir foto',
  settings: 'Configurações da conta',
  changePlatform: 'Alterar Plataforma',
  choosePlatform: 'Selecione abaixo qual plataforma deseja utilizar como padrão.',
  requiredField: 'Campo obrigatório',
  numberOfCharactersRequired: 'Não é permitido o uso de números e caracteres especiais no campo',
  invalidEmail: 'Email inválido'
}
