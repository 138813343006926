import Camera from './camera'
import Check from './check'
import Checked from './checked'
import Edit from './edit'
import Logout from './logout'
import View from './view'
import ViewActive from './view-active'
import LogoEinstein from './logo-einstein'
import LogoCockpit from './logo-cockpit'
import LogoHstory from './logo-hstory'
import Phone from './phone'
import Danger from './danger'
import Confirmed from './confirmed'
import Close from './close'
import Import from './import'

const Icons = {
  Camera,
  Check,
  Checked,
  Edit,
  Logout,
  View,
  ViewActive,
  LogoEinstein,
  Phone,
  Danger,
  Confirmed,
  Close,
  Import,
  LogoCockpit,
  LogoHstory
}

export default Icons
