import React from 'react'

const LogoHstory = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="172" height="29.778" viewBox="0 0 172 29.778">
    <g id="Group_163657" data-name="Group 163657" transform="translate(-97 -17.361)">
      <g id="Group_10801" data-name="Group 10801" transform="translate(97 18.842)">
        <path
          id="Caminho_6287"
          data-name="Caminho 6287"
          d="M796.243,263.1a3.15,3.15,0,0,0-2.369-1.19l-.184-.036a31.562,31.562,0,0,1,2.337,4.069c.065-.171.132-.306.165-.409a2.829,2.829,0,0,0,.049-2.435"
          transform="translate(-774.511 -254.488)"
          fill="#005597"
        />
        <g id="Grupo_10767" data-name="Grupo 10767">
          <path
            id="Caminho_6288"
            data-name="Caminho 6288"
            d="M788.914,270.294c.431,1.157.879,1.737,1.426,1.856.68.137,1.572-.431,2.9-1.856a11.711,11.711,0,0,0,.862-1.038,52.107,52.107,0,0,0-5.419.461c.049.1.231.578.231.578"
            transform="translate(-776.746 -250.904)"
            fill="#005597"
          />
          <path
            id="Caminho_6289"
            data-name="Caminho 6289"
            d="M786.748,267.568a28.559,28.559,0,0,1,2.237,4.376c.2-.034.4-.051.6-.085a35.962,35.962,0,0,1,6.43-.374,45.32,45.32,0,0,0,2.832-4.818,28.86,28.86,0,0,0-3.084-4.8,32.218,32.218,0,0,0-6.115.085,25.6,25.6,0,0,1-1.459,3.016q-.816,1.457-1.438,2.6"
            transform="translate(-777.609 -254.545)"
            fill="#005597"
          />
          <path
            id="Caminho_6290"
            data-name="Caminho 6290"
            d="M802.157,260.751a12.621,12.621,0,0,0-16.9-1.123,13.364,13.364,0,0,0-4.488,6.809,36.016,36.016,0,0,1,10.258-3.029c.265-.869.464-1.686.629-2.4.347-1.5.6-2.57,1.276-2.774.735-.205,1.887.612,3.995,2.824h0L798.531,263a18.919,18.919,0,0,1,2.436.324l.084.018a3.778,3.778,0,0,1,2.651,1.48,2.754,2.754,0,0,1,0,2.3c-.364,1.055-.762,1.991-1.176,2.908a25.594,25.594,0,0,1,.911,2.758.935.935,0,0,0,.033.189.585.585,0,0,1-.149.443l-.065.085-.035-.1a23.916,23.916,0,0,0-.962-2.792,23.588,23.588,0,0,1-1.922,3.285,22.33,22.33,0,0,1,4.719.817,13.686,13.686,0,0,0,.846-4.682,13.307,13.307,0,0,0-3.746-9.274"
            transform="translate(-780.279 -256.904)"
            fill="#005597"
          />
          <path
            id="Caminho_6291"
            data-name="Caminho 6291"
            d="M785.928,269.521c.3.459,1.144.6,2.735.431a30.959,30.959,0,0,0-1.79-3.9c-.845,1.652-1.325,2.878-.943,3.474"
            transform="translate(-778.034 -252.462)"
            fill="#005597"
          />
          <path
            id="Caminho_6292"
            data-name="Caminho 6292"
            d="M793.752,261.793c-1.524-1.856-2.4-2.57-3.016-2.468-.629.1-1.049,1.105-1.657,2.639l-.017.051a34.835,34.835,0,0,1,4.69-.221"
            transform="translate(-776.577 -255.732)"
            fill="#005597"
          />
          <path
            id="Caminho_6293"
            data-name="Caminho 6293"
            d="M799.51,272.772c-.745.954-1.607,1.939-2.7,3.1-1.591,1.685-2.47,2.451-3.182,2.261-.5-.135-.862-.715-1.177-1.924-.282-1-.563-1.974-.878-2.876l-1.16.169c-2.622.391-4.077.171-4.59-.646-.735-1.192.629-3.508,2.5-6.418-.419-.63-.861-1.226-1.325-1.822a.917.917,0,0,1-.116-.153l-.067-.085.1-.016a.6.6,0,0,1,.447.152q.629.691,1.226,1.481l.182-.29.845-1.31a14.368,14.368,0,0,0,1.2-2.38c-4.79.753-8.221,2.261-9.679,4.2a3.213,3.213,0,0,0-.629,2.707,13.53,13.53,0,0,0,3.713,8,12.448,12.448,0,0,0,9.032,3.814,12.684,12.684,0,0,0,9.726-4.594c.58-.8.762-1.481.515-2.009-.366-.8-1.708-1.26-3.979-1.362"
            transform="translate(-780.429 -254.421)"
            fill="#005597"
          />
        </g>
      </g>
      <rect
        id="Rectangle_4082"
        data-name="Rectangle 4082"
        width="0.711"
        height="19.572"
        rx="0.135"
        transform="translate(135 22.214)"
        fill="#32537a"
      />
      <g id="Camada_1-2" transform="translate(144.448 17.361)">
        <rect
          id="Rectangle_141631"
          data-name="Rectangle 141631"
          width="2.893"
          height="1.929"
          transform="translate(23.836 5.462)"
          fill="#318fff"
        />
        <path
          id="Path_27780"
          data-name="Path 27780"
          d="M33.55,14.273V1.65h1.6V6.833h6.3V1.65h1.6V14.273h-1.6V8.324h-6.3v5.951h-1.6Z"
          transform="translate(-28.928 -1.423)"
          fill="#318fff"
        />
        <path
          id="Path_27781"
          data-name="Path 27781"
          d="M254.71,8.783l1.583-.138a3.267,3.267,0,0,0,.575,1.623,3.1,3.1,0,0,0,1.283.934,4.769,4.769,0,0,0,1.878.353,3.927,3.927,0,0,0,2.327-.6,1.831,1.831,0,0,0,.828-1.551,1.63,1.63,0,0,0-.3-.975,2.141,2.141,0,0,0-.908-.683A19.716,19.716,0,0,0,259.353,7a10.429,10.429,0,0,1-2.553-.874,3.3,3.3,0,0,1-1.182-1.115,2.828,2.828,0,0,1-.422-1.528A3.069,3.069,0,0,1,256.41.973,5.081,5.081,0,0,1,259.681,0a6.215,6.215,0,0,1,2.474.456A3.738,3.738,0,0,1,263.8,1.765a3.7,3.7,0,0,1,.628,2.006l-1.615.121a2.5,2.5,0,0,0-.9-1.826,3.511,3.511,0,0,0-2.18-.594,3.829,3.829,0,0,0-2.174.521,1.577,1.577,0,0,0-.749,1.347,1.412,1.412,0,0,0,.548,1.188,7.357,7.357,0,0,0,2.469.854,18.712,18.712,0,0,1,2.637.736,4.109,4.109,0,0,1,1.753,1.272,3.035,3.035,0,0,1,.592,1.879,3.361,3.361,0,0,1-.623,1.974,3.913,3.913,0,0,1-1.693,1.354,6.457,6.457,0,0,1-2.581.464,5.708,5.708,0,0,1-3.752-1.155,3.984,3.984,0,0,1-1.451-3.126Z"
          transform="translate(-219.616 0)"
          fill="#318fff"
        />
        <path
          id="Path_27782"
          data-name="Path 27782"
          d="M388.415,14.245V3.111H383.71V1.62h11.325V3.111h-4.73V14.245h-1.889Z"
          transform="translate(-330.843 -1.397)"
          fill="#318fff"
        />
        <path
          id="Path_27783"
          data-name="Path 27783"
          d="M512.64,6.7a6.564,6.564,0,0,1,1.806-4.964A6.235,6.235,0,0,1,518.945,0a6.584,6.584,0,0,1,3.174.774,5.682,5.682,0,0,1,2.271,2.251,7.024,7.024,0,0,1,.838,3.517,6.5,6.5,0,0,1-1.62,4.573,6.04,6.04,0,0,1-4.673,1.945,5.979,5.979,0,0,1-4.684-2A6.343,6.343,0,0,1,512.64,6.7Zm1.8.022a4.855,4.855,0,0,0,1.307,3.624,4.592,4.592,0,0,0,6.393-.014,5.2,5.2,0,0,0,1.3-3.826,6.219,6.219,0,0,0-.668-2.913,3.864,3.864,0,0,0-1.549-1.573,4.473,4.473,0,0,0-2.26-.591,4.366,4.366,0,0,0-3.245,1.292,5.5,5.5,0,0,0-1.274,4Z"
          transform="translate(-442.009 0)"
          fill="#318fff"
        />
        <path
          id="Path_27784"
          data-name="Path 27784"
          d="M668.58,14.245V1.62h5.572a7,7,0,0,1,2.576.353,3.016,3.016,0,0,1,1.364,1.175,3.424,3.424,0,0,1,.533,1.905,3.1,3.1,0,0,1-.9,2.315A4.539,4.539,0,0,1,675.093,8.5a4.282,4.282,0,0,1,1.066.7,8.953,8.953,0,0,1,1.306,1.611l2.195,3.436h-2.09l-1.675-2.625a16.164,16.164,0,0,0-1.47-2.075,2.77,2.77,0,0,0-.96-.721,3.614,3.614,0,0,0-1.3-.186h-1.921v5.606h-1.662Zm1.662-7.053h3.575a5.2,5.2,0,0,0,1.777-.233,2.053,2.053,0,0,0,.962-.732,1.955,1.955,0,0,0,.356-1.163,1.825,1.825,0,0,0-.679-1.5,3.164,3.164,0,0,0-2.017-.547h-3.972V7.19Z"
          transform="translate(-576.464 -1.397)"
          fill="#318fff"
        />
        <path
          id="Path_27785"
          data-name="Path 27785"
          d="M797.619,14.236V8.889L792.25,1.611H794.5l2.738,3.81q.812,1.128,1.427,2.138.616-.992,1.523-2.233L802.9,1.61h2.131l-5.566,7.276v5.347h-1.843Z"
          transform="translate(-683.095 -1.388)"
          fill="#318fff"
        />
        <path
          id="Path_27786"
          data-name="Path 27786"
          d="M223.937,157.879a6.482,6.482,0,0,0-1.669-.15l-3.268,0,.007,7.969h1.374l0-3.133,1.991,0a3.635,3.635,0,0,0,2.508-.7,2.363,2.363,0,0,0,.738-1.793,2.21,2.21,0,0,0-.457-1.409,2.3,2.3,0,0,0-1.219-.787Zm-.435,1.135a1.063,1.063,0,0,1,.5.4,1.2,1.2,0,0,1,.208.708,1.1,1.1,0,0,1-.415.949,2.4,2.4,0,0,1-1.4.324l-2.012,0,0-2.488,1.991,0a3.78,3.78,0,0,1,1.135.112Z"
          transform="translate(-188.826 -135.997)"
          fill="#45e6d4"
        />
        <path
          id="Path_27787"
          data-name="Path 27787"
          d="M306.989,164.454l-.007-6.794H305.44l.008,7.969,6.257-.006V164.45Z"
          transform="translate(-263.357 -135.938)"
          fill="#45e6d4"
        />
        <path
          id="Path_27788"
          data-name="Path 27788"
          d="M378.072,157.571l-3.812,7.972H375.9l1.039-2.335,3.849,0,1.116,2.333h1.759L379.6,157.57h-1.528Zm2.2,4.54-2.834,0,.975-2.074a8.641,8.641,0,0,0,.417-1.084c.136.343.315.748.533,1.21l.909,1.945Z"
          transform="translate(-322.695 -135.86)"
          fill="#45e6d4"
        />
        <path
          id="Path_27789"
          data-name="Path 27789"
          d="M471.186,157.48l-7.866.007v1.175l3.154,0,.007,6.794h1.542l-.007-6.794,3.17,0Z"
          transform="translate(-399.484 -135.783)"
          fill="#45e6d4"
        />
        <path
          id="Path_27790"
          data-name="Path 27790"
          d="M561.466,157.41l-6.736.006.008,7.969h1.542l0-3.5,4.492,0V160.7l-4.493,0v-2.119l5.193-.006Z"
          transform="translate(-478.3 -135.722)"
          fill="#45e6d4"
        />
        <path
          id="Path_27791"
          data-name="Path 27791"
          d="M642.043,156.838A4.337,4.337,0,0,0,640,156.35h-.006a3.9,3.9,0,0,0-4.073,4.226,3.929,3.929,0,0,0,4.067,4.011h.005a3.965,3.965,0,0,0,3.021-1.235,4.061,4.061,0,0,0,1.046-2.881,4.344,4.344,0,0,0-.543-2.211,3.664,3.664,0,0,0-1.474-1.425Zm-2.061,6.612a2.633,2.633,0,0,1-1.886-.745,2.809,2.809,0,0,1-.778-2.116,3.2,3.2,0,0,1,.756-2.348,2.642,2.642,0,0,1,1.929-.751h0a2.714,2.714,0,0,1,1.341.342,2.267,2.267,0,0,1,.915.9,3.621,3.621,0,0,1,.4,1.717,3.022,3.022,0,0,1-.769,2.243,2.63,2.63,0,0,1-1.908.756h0Z"
          transform="translate(-548.304 -134.808)"
          fill="#45e6d4"
        />
        <path
          id="Path_27792"
          data-name="Path 27792"
          d="M736.114,161.917a2.38,2.38,0,0,0-.391-.265,3.15,3.15,0,0,0,1.465-.656,1.918,1.918,0,0,0,.665-1.511,2.046,2.046,0,0,0-.393-1.239,2.177,2.177,0,0,0-1-.763,5.41,5.41,0,0,0-1.808-.223h-.014l-3.968,0,.007,7.969h1.418l0-3.422h1.189a2.659,2.659,0,0,1,.84.1,1.767,1.767,0,0,1,.61.409,9.958,9.958,0,0,1,1,1.247l1.195,1.657h1.772l-1.66-2.305a5.89,5.89,0,0,0-.92-1.006Zm-1.426-3.54a2.257,2.257,0,0,1,1.3.306.933.933,0,0,1,.412.81.971.971,0,0,1-.215.628,1.287,1.287,0,0,1-.6.4,3.873,3.873,0,0,1-1.175.135l-2.32,0V158.38l2.59,0h.005Z"
          transform="translate(-629.999 -135.593)"
          fill="#45e6d4"
        />
        <path
          id="Path_27793"
          data-name="Path 27793"
          d="M832.743,157.131h-1.651l-1.933,5.453q-.172.49-.292.843l-.248-.747-1.922-5.543H824.88l.007,7.971h1.287l0-5.882,2.072,5.878h1.141l2.076-5.791,0,5.787h1.287l-.007-7.971Z"
          transform="translate(-711.229 -135.481)"
          fill="#45e6d4"
        />
        <path
          id="Path_27794"
          data-name="Path 27794"
          d="M18.231,157.49,15,165.459h1.443l.88-2.333h3.2l.942,2.333h1.542l-3.431-7.969H18.232Zm1.853,4.54H17.74l.829-2.082a9.449,9.449,0,0,0,.327-.984q.165.482.416,1.111Z"
          transform="translate(-12.933 -135.791)"
          fill="#45e6d4"
        />
        <rect
          id="Rectangle_141632"
          data-name="Rectangle 141632"
          width="1.29"
          height="7.969"
          transform="translate(15.008 21.699)"
          fill="#45e6d4"
        />
        <rect
          id="Rectangle_141633"
          data-name="Rectangle 141633"
          width="124.552"
          height="0.689"
          transform="translate(0 17.311)"
          fill="#eaeaea"
        />
      </g>
    </g>
  </svg>
)

export default LogoHstory
