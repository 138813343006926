import React, { ReactElement } from 'react'

import UserMenuContainer from '../UserMenuContainer/UserMenuContainer'
import { StyledHeader, UserMenuContent } from './styles'
import SelectPlatform from './SelectPlatform'
import { EditUserModalProvider, SecurityEditModalProvider } from '../EditUserModal/context'

import ModalsEditUser from './ModalsEditUser'

const Header: React.FC = (): ReactElement => {
  return (
    <EditUserModalProvider>
      <SecurityEditModalProvider>
        <StyledHeader>
          <SelectPlatform />
          <UserMenuContent>
            <UserMenuContainer />
          </UserMenuContent>
        </StyledHeader>
        <ModalsEditUser />
      </SecurityEditModalProvider>
    </EditUserModalProvider>
  )
}

export default Header
