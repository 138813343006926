import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom'
import DefaultLayout from '../components/_layouts/default/DefaultTemplates'
import { readLocalStorage, deleteLocalStorage } from '../modules/local-storage/local-storage.service'
import { SessionData } from '../modules/login/Session'
import { selectUserSession, sessionUser, setIsContextSSO } from '../modules/login/sessionSlice'
import { sessionValidation } from '../shared/functions/sessionValidation'

import { useCclParamsHelper } from './cclParamsHelper'
import LocalStorageManager from '../helpers/localStorage'

interface RouteWrapperProps extends RouteProps {
  protectedRoute: boolean
  guard?: Function
  component: React.FunctionComponent
}

export const RouteWrapper: React.FC<RouteWrapperProps> = ({
  component: Component,
  protectedRoute,
  guard,
  ...rest
}: any) => {
  const location = useLocation()

  if (guard) {
    guard()
  }
  const dispatch = useDispatch()

  // Garante recuperação dos dados do storage ao atualizar a página
  // também salva os dados da sessão no storage do redux
  let sessionData: SessionData | null = null
  const userSession: SessionData | null = useSelector(selectUserSession)
  if (!userSession) {
    sessionData = LocalStorageManager.ReadEncryptedData<SessionData>('session') as SessionData
    if (sessionData) {
      dispatch(sessionUser(sessionData))
    }
  } else {
    sessionData = userSession
  }

  const isContextSSO = readLocalStorage('is_context_sso')

  if (isContextSSO) {
    dispatch(setIsContextSSO(true))
    deleteLocalStorage('is_context_sso')
    deleteLocalStorage('is_context_sso-encrypted')
  }

  return (
    <Route
      {...rest}
      render={(routeProps: RouteProps): any => {
        return sessionData && protectedRoute && sessionValidation() ? (
          <DefaultLayout>
            <Component {...routeProps} />
          </DefaultLayout>
        ) : (
          <Redirect to={{ pathname: '/login', search: location.search }} />
        )
      }}
    />
  )
}

export const PlatformOrLogin: React.FC<RouteProps> = ({ ...rest }) => {
  const location = useLocation()
  const result = !useCclParamsHelper() && sessionValidation()
  return result ? (
    <Redirect to={{ pathname: '/dashboard', state: { from: rest.location } }} />
  ) : (
    <Redirect to={{ pathname: '/login', search: location.search }} />
  )
}
