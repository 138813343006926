import LocalStorageManager from '../../helpers/localStorage'
import { addCertificateLocalStorage, deleteLocalStorage } from '../local-storage/local-storage.service'
import { todayTime, TWENTY_MINUTES_IN_MS } from './constants'
import { loginApi } from './login.api'
import { SessionData } from './Session'
import { User } from './User'

export const login = async (user: string, password: string): Promise<SessionData> => {
  return loginApi
    .post(user, password)
    .then(async (session: SessionData) => Promise.resolve(session))
    .catch(async e => Promise.reject(e))
}

export const loginActiveDirectory = async (username: string, password: string): Promise<boolean> => {
  return loginApi
    .postActiveDirectory(username, password)
    .then(async (response: boolean) => Promise.resolve(response))
    .catch(async e => Promise.reject(e))
}

export const logout = (): void => {
  deleteLocalStorage('verifica_token')
  deleteLocalStorage('verifica_token_expires_in')
  deleteLocalStorage('verifica_token_expiration_date')
  deleteLocalStorage('access_token_expires_in')
  deleteLocalStorage('session')
  deleteLocalStorage('office')
  deleteLocalStorage('permissions')
  deleteLocalStorage('permissions-encrypted')
  deleteLocalStorage('verifica_token-encrypted')
  deleteLocalStorage('verifica_token_expires_in-encrypted')
  deleteLocalStorage('verifica_token_expiration_date-encrypted')
  deleteLocalStorage('access_token_expires_in-encrypted')
  deleteLocalStorage('session-encrypted')
  deleteLocalStorage('office-encrypted')
  deleteLocalStorage('is_context_sso-encrypted')
}

export const addCertificate = (certificate: string) => {
  addCertificateLocalStorage('session', certificate)
  LocalStorageManager.WriteEncryptedData('access_token_expires_in-encrypted', todayTime + TWENTY_MINUTES_IN_MS)
}

export const loginSSO = async (signature: string, externalUsername: string): Promise<SessionData> => {
  return loginApi
    .postSSOCernerMiddleware(signature, externalUsername)
    .then(async (session: SessionData) => Promise.resolve(session))
    .catch(async e => Promise.reject(e))
}

export const updateUser = async (userId: number, requestData: object, token: string): Promise<User> => {
  return loginApi
    .updateUser(userId, requestData, token)
    .then(async (session: User) => Promise.resolve(session))
    .catch(async e => Promise.reject(e))
}

export const loginService = {
  login,
  loginActiveDirectory,
  logout,
  loginSSO,
  updateUser
}
