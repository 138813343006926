import React from 'react'

const css = `
  .a{fill:#378ff7;}
`

const Checked = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-testid="checked-icon" xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65"><defs><style>{css}</style></defs><g transform="translate(13.378 8.505)"><path className="a" d="M171.254,65.077a2.551,2.551,0,0,0-3.608,0L137.039,95.6,126,83.605a2.551,2.551,0,0,0-3.754,3.456l12.842,13.946a2.548,2.548,0,0,0,1.823.822h.054a2.554,2.554,0,0,0,1.8-.744l32.487-32.4A2.551,2.551,0,0,0,171.254,65.077Z" transform="translate(-121.568 -64.327)"/></g><path className="a" d="M62.55,30.05A2.45,2.45,0,0,0,60.1,32.5,27.6,27.6,0,1,1,32.5,4.9a2.45,2.45,0,1,0,0-4.9A32.5,32.5,0,1,0,65,32.5,2.45,2.45,0,0,0,62.55,30.05Z"/></svg>
)

export default Checked
