import React from 'react'
import { Routes } from './routes/Routes'
import store from './modules/store/store'
import { theme } from './components/Theme/Theme'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import ErrorBoundary from './components/ErrorBoundary'

const App: React.FC = () => {
  return (
    <div>
      <ErrorBoundary>
        <Provider store={store}>
          <BrowserRouter>
            <React.Fragment>
              <ThemeProvider theme={theme}>
                <Routes />
              </ThemeProvider>
            </React.Fragment>
          </BrowserRouter>
        </Provider>
      </ErrorBoundary>
    </div>
  )
}

export default App
