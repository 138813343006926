import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
export const useCclParamsHelper = (): string | null => {
  const { search } = useLocation()

  const queryParams = useMemo(() => new URLSearchParams(search), [search])

  const signatureParams = queryParams.get('signature')

  const result = signatureParams
  return result
}
