import React, { ReactElement, useState } from 'react'
import { UserData, UserImage, UserMenuContainer, UserName, ClassCode } from './styles'
import { User } from '../../../modules/login/User'
import { UserSidebar } from '../../UserSidebar/UserSidebar'
import { SideMenuModal } from '../../sideMenuModal/SideMenuModal'
import { Tooltip, UserAvatar } from '@cockpit/zera'
import removePrefixDoctor from '../../../helpers/removePrefixDoctor'

const UserMenu: React.FC<{ user: User | null }> = ({ user }): ReactElement | null => {
  const [showModal, setShowModal] = useState(false)

  const openModal = () => {
    const menuToggle = !showModal
    setShowModal(menuToggle)
  }

  const limitCharacter = (string: string, limit: number) => {
    const text = string || ''
    if (text && text.length > limit) {
      return <Tooltip description={string}>{string.substring(0, limit)}...</Tooltip>
    }
    return string
  }

  let card = <div></div>
  if (user) {
    card = (
      <UserMenuContainer data-testid="user-menu-container">
        <UserData>
          <div>
            <UserName id="user-name" data-testid="user-name-field">
              {limitCharacter(user.Objeto?.[0]?.NomeUsuario || '', 25)}
            </UserName>
            <ClassCode>{user.Objeto?.[0]?.CodRegistro}</ClassCode>
          </div>
        </UserData>
        <UserImage data-testid="user-image" onClick={openModal}>
          <UserAvatar name={removePrefixDoctor(user?.Objeto?.[0]?.NomeUsuario || '')} image={''} />
        </UserImage>
        <SideMenuModal showModal={showModal} setShowModal={setShowModal}>
          <UserSidebar user={user} />
        </SideMenuModal>
      </UserMenuContainer>
    )
  }

  return card
}

export default UserMenu
