import { SessionData } from '../../modules/login/Session'
import { getPlatformRoute } from '../../models/platformRoute'

import LocalStorageManager from '../../helpers/localStorage'
import ENV from '../../modules/env'

export type IWithoutTheRouteParameter = (
  userSession?: SessionData | null,
  queryParamsSSO?: {
    patientEpisodeNumber?: string | null
    target?: string | null
  }
) => void

/**
 * Tem como função pegar e setar uma plataforma ou consultório para o usuário quando é chamado apenas o caminho raiz /
 * ou pega uma plataforma ou consultório default do usuário
 */
export const withoutTheRouteParameter: IWithoutTheRouteParameter = (userSession, queryParamsSSO) => {
  if (queryParamsSSO?.patientEpisodeNumber) {
    return getPlatformRoute(ENV.HSTORY_PLATFORM_ID, {
      patientEpisodeNumber: queryParamsSSO.patientEpisodeNumber,
      target: queryParamsSSO.target
    })
  }

  return getPlatformRoute(ENV.HSTORY_PLATFORM_ID)
}
/**
 * Utilizado para verificar se a sessão do usuário está correta. Se por algum motivo foi perdido algum dado importante na sessão, essa  função serve para resetar a mesma!
 *
 * @param userSession
 * @returns retorna se a sessão está valida ou não
 */
export const sessionValidation = (userSession?: SessionData): boolean | null => {
  let sessionData = LocalStorageManager.ReadEncryptedData<SessionData>('session') as SessionData
  if (userSession) {
    sessionData = userSession
  }

  if (!sessionData) return null

  return true
}
