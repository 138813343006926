import dotenv from 'dotenv'
dotenv.config()

const ENV = {
  HSTORY_HOST: process.env.REACT_APP_HSTORY_HOST,
  HSTORY_PLATFORM_ID: Number(process.env.REACT_APP_HSTORY_PLATFORM_ID),

  API_BASE_URL: process.env.REACT_API_BASE_URL,
  API_LOGIN: process.env.REACT_APP_API_LOGIN,
  API_AUTHENTICATION: process.env.REACT_APP_API_AUTHENTICATION,
  API_USER_AUTH: process.env.REACT_APP_API_USER_AUTH,
  IMAGES_URL: process.env.REACT_APP_IMAGES_URL,
  SKIP_PREFLIGHT_CHECK: process.env.SKIP_PREFLIGHT_CHECK,
  APP_NAME: process.env.APP_NAME,
  API_USER_FORGOT: process.env.REACT_APP_API_USER_FORGOT,

  FIREBASE_DOMAIN: process.env.REACT_APP_FIREBASE_DOMAIN,
  FIREBASE_APPID: process.env.REACT_APP_FIREBASE_APPID,
  FIREBASE_MEASUREMENT: process.env.REACT_APP_FIREBASE_MEASUREMENT,
  FIREBASE_SENDERID: process.env.REACT_APP_FIREBASE_SENDERID,
  FIREBASE_APIKEY: process.env.REACT_APP_FIREBASE_APIKEY
}

export default ENV
