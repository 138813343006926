import styled from 'styled-components'

export const Container = styled.div`
  button {
    font: 400 16px 'Inter';
    color: #545e6c;
    padding: 0px 27.5px;
    margin: 0px;

    svg {
      margin-top: -3px;
    }

    :nth-child(3) {
      height: 60px;
      border-top: 1px solid #dfe3e8;
      border-bottom: 1px solid #dfe3e8;
      margin: 8px 0px;
    }
  }
`

export const Header = styled.h2`
  font: 500 16px 'Inter';
  margin: 40px 24px 24px 24px;
  color: #34373b;
`

export const FooterText = styled.p`
  font: normal 16px 'Inter';
  margin: 48px 24px 24px 24px;
  color: #34373b;

  @media (max-width: 385px) {
    font-size: 14px;
  }
`
