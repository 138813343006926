import { IconExporter, Modal, theme, Tooltip } from '@cockpit/zera'
import { ModalProps } from '@cockpit/zera/dist/Modal/Modal'
import React from 'react'
import { Title } from '../../../../styles/shared'
import { messages } from '../../constants/Messages'
import { useEditUserModal } from '../../context'
import { BoxLeft, ButtonLeft, ContainerButtonLeft, DisplayMobileBoxIconArrow, MobileBoxIconArrow } from './styles'

interface ISignatureModal extends ModalProps {}

export const SignatureModal: React.FC<ISignatureModal> = ({ isMobileFullscreen, ...rest }: ISignatureModal) => {
  const { getOpenEditModal, setOpenSignatureModal } = useEditUserModal()

  return (
    <Modal
      {...rest}
      closeClickMask={rest.closeClickMask ?? false}
      size={'small'}
      isMobileFullscreen
      modalBodyPadding="0"
      hideModalCloseButton
      customFullscreenCloseButton={false}
    >
      <MobileBoxIconArrow>
        <DisplayMobileBoxIconArrow>
          <IconExporter
            data-testid="button-return"
            name="arrow_left"
            iconsize="20px"
            onClick={() => setOpenSignatureModal(false)}
          />
        </DisplayMobileBoxIconArrow>
      </MobileBoxIconArrow>
      <ContainerButtonLeft>
        <BoxLeft>
          <ButtonLeft data-testid="button-desktop" onClick={() => getOpenEditModal(true)}>
            <Tooltip description={'Voltar'}>
              <IconExporter name="arrow_left" iconsize="10px" />
            </Tooltip>
          </ButtonLeft>
          <Title fontSize="20px" fontWeight={theme.fontWeight.semibold} marginLeft="20px">
            {messages.signature}
          </Title>
        </BoxLeft>
        <IconExporter
          data-testid="button-close-modal"
          name="close"
          iconsize={'15px'}
          onClick={() => setOpenSignatureModal(false)}
        />
      </ContainerButtonLeft>
    </Modal>
  )
}
