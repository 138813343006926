import styled, { css } from 'styled-components'

interface ContainerProps {
  visible: boolean
  noCloseButton: boolean
  margin?: string
  maxWidth?: string
  showByProperty: 'visibility' | 'display'
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: #ffe9e9;
  border-radius: 4px;
  margin: ${({ margin }) => margin ?? '0px'};
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth};
  min-height: 2rem;
  text-align: center;

  ${({ noCloseButton }) =>
    !noCloseButton
      ? css`
          padding: 7px 16px 7px 48px;

          @media (max-width: 500px) {
            padding: 7px 8px 7px 40px;
          }
        `
      : css`
          padding: 7px 16px;

          @media (max-width: 500px) {
            padding: 7px 8px;
          }
        `}

  ${({ showByProperty, visible }) => {
    if (showByProperty === 'display') {
      return css`
        display: ${visible ? 'flex' : 'none'};
      `
    }
  }}

    ${({ showByProperty, visible }) => {
    if (showByProperty === 'visibility') {
      return css`
        visibility: ${visible ? 'visible' : 'hidden'};
      `
    }
  }}

    button {
    width: 0;
    height: 0;
    padding: 8px;
    margin: 0 0 0 16px;
    border: none;
    background-color: transparent;
    transition: none;

    &:hover {
      background-color: transparent;
    }

    svg {
      opacity: 0.3;
    }
  }
`

export const Text = styled.p`
  color: #ff2424;
  font-size: 0.875rem;
  width: 100%;
  line-height: 1.4;

  @media (max-width: 500px) {
    font-size: calc(0.875rem + 1px);
  }
`
