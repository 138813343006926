import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useCustomEvent } from '../../../shared/functions/useCustomEvent'

import Header from '../../header/Header'
import { AppWrapper, MainContent } from './styles'
import { PreloadingScreen } from '../../LoaderScreen/PreloadingScreen'
import { getPreloading, getProgress, setPreloading, setProgress } from '../../../modules/session/sessionSlice'

export interface ScreenLoadingEvent {
  finishedLoading: boolean
}

const DefaultLayout: React.FC = ({ children }: any) => {
  const isVisible = useSelector(getPreloading)
  const progress = useSelector(getProgress)
  const dispatch = useDispatch()
  const { subscribe } = useCustomEvent<ScreenLoadingEvent>('screen-loading')

  useEffect(() => {
    if (subscribe?.finishedLoading) {
      dispatch(setProgress(100))
      setTimeout(() => dispatch(setPreloading(!subscribe.finishedLoading)), 1000)
    }
  }, [subscribe])

  return (
    <AppWrapper id="app-wrapper">
      <PreloadingScreen visible={isVisible} percentage={progress} />
      <Header />
      <MainContent>{children}</MainContent>
    </AppWrapper>
  )
}

export default DefaultLayout
