import { IGetSessionErrorKey, IDynamicErrorMessage } from './interfaces'

export const getSessionErrorKey: IGetSessionErrorKey = sessionErrors => {
  if (sessionErrors?.error === 'unauthorizedProfile') {
    return 'unauthorizedProfile'
  }

  if (sessionErrors?.error?.includes('locked')) {
    return 'userIsBlocked'
  }

  return 'incorrectCredentials'
}

export const dynamicErrorMessage: IDynamicErrorMessage = (property, error) => {
  if (error && error === 'incorrectPassword') {
    const singularOrPlural = property === 1 ? 'tentativa' : 'tentativas'
    return `Usuário e/ou senha incorretos, você possui mais ${property} ${singularOrPlural}`
  }
}
