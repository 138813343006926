import { AxiosRequestConfig, AxiosResponse } from 'axios'
import ENV from '../env'
import api, { errorHandling } from '../http/api'
import { SessionData } from './Session'
import { User } from './User'

const BASE_ENDPOINT = '/session/'
export interface Login {
  username: string
  password: string
}

interface LoginSSO {
  signature: string
  external_username: string
}

const post = async (username: string, password: string): Promise<SessionData> => {
  const loginData = {
    username: username,
    password: password
  }

  const requestConfig: AxiosRequestConfig = {
    baseURL: ENV.API_LOGIN,
    headers: {
      'Content-Type': 'application/json',
      'active-directory': 'true'
    }
  }

  return api
    .post<Login, AxiosResponse<SessionData>>(BASE_ENDPOINT, loginData, requestConfig)
    .then(response => response.data)
    .catch(errorHandling)
}

const postActiveDirectory = async (username: string, password: string): Promise<boolean> => {
  const loginData = {
    username: username,
    password: password
  }

  const requestConfig: AxiosRequestConfig = {
    baseURL: ENV.API_LOGIN
  }

  return api
    .post<Login, AxiosResponse<{ user: { id: number } }>>('/session/activeDirectory', loginData, requestConfig)
    .then(response => !!response?.data?.user?.id)
    .catch(errorHandling)
}

const postSSOCernerMiddleware = async (signature: string, externalUsername: string): Promise<SessionData> => {
  const loginSSOData = {
    signature: signature,
    external_username: externalUsername
  }

  const requestConfig: AxiosRequestConfig = {
    baseURL: ENV.API_LOGIN
  }

  return api
    .post<LoginSSO, AxiosResponse<SessionData>>('/session/sso', loginSSOData, requestConfig)
    .then(response => response.data)
    .catch(errorHandling)
}

const updateUser = async (userId: number, requestData: object, token: string): Promise<User> => {
  const requestConfig: AxiosRequestConfig = {
    baseURL: ENV.API_LOGIN,
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  return api
    .put<LoginSSO, AxiosResponse<User>>(`/user/${userId}`, requestData, requestConfig)
    .then(response => response.data)
    .catch(errorHandling)
}

export const loginApi = {
  post,
  postActiveDirectory,
  postSSOCernerMiddleware,
  updateUser
}
