import React, { useState, useEffect } from 'react'

import { Button, Input } from '@cockpit/zera'

import { useForm } from 'react-hook-form'

import Icons from '../../../assets/icons'
import { ResponsiveModal } from '../../../shared/ResponsiveModal'

import { Container, Title, Description, Options, InputBox, PasswordViewer, LabelInput, ErrorMsg } from './styles'

import { useDispatch, useSelector } from 'react-redux'

import { loginActiveDirectory, selectSessionErrors, selectLoading } from '../../../modules/login/sessionSlice'
import { message } from '../../../messages'

interface Props {
  showModal: boolean
  onClose: () => void
}

const { ViewActive, View } = Icons

interface Inputs {
  user: string
  password: string
}

export const LoginActiveDirectory: React.FC<Props> = ({ showModal, onClose }) => {
  const dispatch = useDispatch()
  const sessionErrors = useSelector(selectSessionErrors)

  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { register, handleSubmit } = useForm<Inputs>()
  const loadingActiveDirectory = useSelector(selectLoading)

  useEffect(() => {
    if (loadingActiveDirectory === 'failing') {
      setIsLoading(false)
    }
  }, [loadingActiveDirectory])

  const onSubmit = (data: Inputs) => {
    setIsLoading(true)
    dispatch(loginActiveDirectory(data.user, data.password))
  }

  const getErrorMessage = () => {
    if (sessionErrors && sessionErrors.error === 'unauthorizedProfileActiveDirectory') {
      return message('activeDirectory.loginInvalid')
    }

    if (sessionErrors && sessionErrors.error === 'externalExistsActiveDirectory') {
      return message('activeDirectory.externalExists')
    }

    return ''
  }

  const messageError = getErrorMessage()

  return (
    <ResponsiveModal
      show={showModal}
      closeOnClickMask={false}
      onClose={onClose}
      width="470px"
      padding="0 40px 32px"
      hideCloseIcon
    >
      <Container>
        <Title>{message('activeDirectory.hello')}</Title>
        <Description>{message('activeDirectory.descriptionModal')}</Description>
        {messageError && !isLoading && <ErrorMsg data-testid="login-error-msg">{messageError}</ErrorMsg>}
        <LabelInput>{message('activeDirectory.user')}</LabelInput>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputBox>
            <Input {...register('user', { required: true })} type="text" placeholder="E-mail institucional" />
          </InputBox>
          <LabelInput>{message('activeDirectory.password')}</LabelInput>
          <InputBox>
            <Input
              onPaste={(e: any) => {
                e.preventDefault()
                return false
              }}
              onCopy={(e: any) => {
                e.preventDefault()
                return false
              }}
              {...register('password', { required: true })}
              placeholder="Senha"
              type={isPasswordVisible ? 'text' : 'password'}
              margin={'5px 0 10px 0'}
            />

            <PasswordViewer onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
              {isPasswordVisible ? <ViewActive /> : <View />}
            </PasswordViewer>
          </InputBox>

          <Options>
            <Button type="button" variant="outlined" className="btn-later" onClick={onClose}>
              {message('activeDirectory.linkLater')}
            </Button>
            <Button type="submit" isLoading={isLoading}>
              {message('activeDirectory.confirm')}
            </Button>
          </Options>
        </form>
      </Container>
    </ResponsiveModal>
  )
}
