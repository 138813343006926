import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { getCurrentPlatform } from '../../../modules/session/sessionSlice'
import { Container, LogoContainer, TitlePlatform, Mask } from './styles'
import Icons from '../../../assets/icons'

const { LogoHstory } = Icons

const SelectPlatform: React.FC = () => {
  const [show, setShow] = useState(false)
  const currentPlatformOrOffice = useSelector(getCurrentPlatform)
  const userHasOnlyOnePlatformOrOffice = false

  return (
    <Container>
      <LogoContainer
        onClick={() => {
          if (userHasOnlyOnePlatformOrOffice) {
            setShow(!show)
          }
        }}
        show={show}
        onlyHaveOnePlatformOrOffice={userHasOnlyOnePlatformOrOffice}
      >
        <LogoHstory />
        <TitlePlatform data-testid="title-platform-header">{currentPlatformOrOffice?.name}</TitlePlatform>
        {/* {userHasOnlyOnePlatformOrOffice && (
          <ArrowPlat show={show}>
            <IconExporter name="arrow_right" iconsize={10} />
          </ArrowPlat>
        )} */}
      </LogoContainer>

      <Mask show={userHasOnlyOnePlatformOrOffice && show} />
    </Container>
  )
}

export default SelectPlatform
