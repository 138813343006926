import { UserAvatar, theme, Input, Tooltip, IconExporter } from '@cockpit/zera'
import React from 'react'
import { get, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { selectUserSession } from '../../../../modules/login/sessionSlice'
import { Separator, Title } from '../../../../styles/shared'
import { EmojiRegex } from '../../../../utils/regex'
import useResize from '../../../_layouts/useResize'
import { messages } from '../../constants/Messages'
import { useEditUserModal } from '../../context'
import {
  ContainerUserAvatarEditModal,
  ProfileDataUser,
  TitleName,
  FormContainer,
  TitleForm,
  InputContentGrid,
  InputBox,
  ErrorMessage,
  ButtonBox,
  ContainerMessages,
  SuccessMessage,
  DisplayBoxUserNameWithIcon,
  UserSidebarAvatar,
  ErrorMessageRequeridField
} from './styles'

export interface IForm {
  name: string
  email: string
  phone: string
  classCode: string
}

export const UserEditModal: React.FC = (): JSX.Element => {
  const { register, formState, watch } = useForm<IForm>()

  const sessionData = useSelector(selectUserSession)

  const windowSize = useResize()

  const {
    phoneErrorMessage,
    updatePerfilError,
    updatePerfilSuccess,
    setPhoneErrorMessage,
    setClassCodeValidation,
    setPhone,
    setUpdatePerfilSuccess,
    setUpdatePerfilError
  } = useEditUserModal()

  const watchClassCode = watch('classCode')

  React.useEffect(() => {
    setUpdatePerfilSuccess(false)
    setUpdatePerfilError('')
  }, [])

  React.useEffect(() => {
    if (watchClassCode?.match(EmojiRegex)) {
      setClassCodeValidation(false)
    } else {
      setClassCodeValidation(true)
    }
  }, [watchClassCode])

  const handleInputPhone = React.useCallback(event => {
    if (event.target.value.length > 2 && event.target.value.length < 15) {
      setPhoneErrorMessage(messages.incompletedPhone)
    } else if (event.target.value.length <= 1) {
      setPhoneErrorMessage(messages.requiredField)
    } else {
      setPhone(event.target.value)
      setPhoneErrorMessage('')
    }
  }, [])

  return (
    <>
      <ContainerUserAvatarEditModal>
        <UserSidebarAvatar>
          <UserAvatar name={sessionData?.Objeto?.[0]?.NomeUsuario ?? ''} image={''} />
        </UserSidebarAvatar>
        <ProfileDataUser>
          <TitleName>{sessionData?.Objeto?.[0]?.NomeUsuario}</TitleName>
          <Title fontSize="14px" color={theme.colors.gray4} marginTop="2%">
            {sessionData?.Objeto?.[0]?.DescEmail}
          </Title>
        </ProfileDataUser>
      </ContainerUserAvatarEditModal>
      <Title
        textTransform="uppercase"
        marginBottom={theme.spacing.medium}
        marginTop={theme.spacing.large}
        fontWeight="bold"
        color="#6B7786"
      >
        {messages.dataProfile}
      </Title>
      <Separator />
      <FormContainer>
        <TitleForm>{messages.fullName}</TitleForm>
        <Input
          width={'470px'}
          {...register('name', {
            required: messages.requiredField,
            pattern: {
              value: /^[ a-zA-ZÀ-ÿ]*$/g,
              message: messages.numberOfCharactersRequired
            }
          })}
          type={'text'}
          defaultValue={sessionData?.Objeto?.[0]?.NomeUsuario}
          error={get(formState.errors, 'name')}
        />
        {get(formState.errors, 'name') && (
          <ErrorMessageRequeridField role="alert">{get(formState.errors, 'name')?.message}</ErrorMessageRequeridField>
        )}
        <InputContentGrid>
          <InputBox>
            <DisplayBoxUserNameWithIcon>
              <TitleForm>{messages.userName}</TitleForm>
              <Tooltip
                position={windowSize >= 768 ? 'right' : 'top'}
                description={'Nome utilizado para realizar o login'}
              >
                <IconExporter margin="0 0 8px 10px" name="info_icon" iconsize="18px" />
              </Tooltip>
            </DisplayBoxUserNameWithIcon>
            <Input disabled type={'text'} defaultValue={sessionData?.Objeto?.[0]?.NomeUsuario} />
          </InputBox>
          <InputBox>
            <TitleForm>{messages.email}</TitleForm>
            <Input
              {...register('email', {
                required: messages.requiredField,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: messages.invalidEmail
                }
              })}
              type="email"
              defaultValue={sessionData?.Objeto?.[0]?.DescEmail}
              error={get(formState.errors, 'email')}
            />
            {get(formState.errors, 'email') && (
              <ErrorMessageRequeridField role="alert">
                {get(formState.errors, 'email')?.message}
              </ErrorMessageRequeridField>
            )}
          </InputBox>
        </InputContentGrid>
        <InputContentGrid>
          <InputBox>
            <TitleForm>{messages.CPF}</TitleForm>
            <Input disabled type={'number'} />
          </InputBox>
          <InputBox>
            <TitleForm>{messages.phone}</TitleForm>
            <Input
              {...register('phone', {
                required: true
              })}
              disabled
              data-testid="phone-input"
              mask=""
              onChange={handleInputPhone}
              type="tel"
              error={phoneErrorMessage !== ''}
            />
            {phoneErrorMessage !== '' && (
              <ErrorMessageRequeridField role="alert">{phoneErrorMessage}</ErrorMessageRequeridField>
            )}
          </InputBox>
        </InputContentGrid>
        <ButtonBox>
          <ContainerMessages>
            {updatePerfilError && (
              <ErrorMessage>
                <Title color={theme.colors.red1} fontSize="12px">
                  {updatePerfilError === 'emailError' ? messages.updatePerfilEmailError : messages.updatePerfilError}
                </Title>
              </ErrorMessage>
            )}
            {updatePerfilSuccess && (
              <SuccessMessage>
                <Title color={theme.colors.green1} fontSize="12px">
                  {messages.updatePerfilSuccess}
                </Title>
              </SuccessMessage>
            )}
          </ContainerMessages>
        </ButtonBox>
      </FormContainer>
    </>
  )
}
