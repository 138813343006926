import { Button } from '@cockpit/zera'
import React, { Component, ErrorInfo } from 'react'
import Icons from '../../assets/icons'
import { Animation, Container } from './styles'

interface Props {
  children?: JSX.Element
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error('Uncaught error:', error, errorInfo)
  }

  public render() {
    if (this.state.hasError) {
      // fallback UI:
      return (
        <Container>
          <Animation>
            <Icons.LogoHstory />
            <div>
              <h1>Algo deu errado. {'\n'} Por favor, recarregue a página!</h1>
            </div>
            <Button type="button" onClick={() => window.location.reload()}>
              Recarregar
            </Button>
          </Animation>
        </Container>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
