import React from 'react'
import PortalModal from '../../shared/PortalModal'
import { Mask } from './styles'

export const SideMenuModal: React.FC<{
  showModal: boolean
  setShowModal: Function
}> = ({ showModal, setShowModal, children }) => {
  return (
    <React.Fragment>
      <PortalModal
        show={showModal}
        closeClickMask={false}
        onClose={() => setShowModal(false)}
        useMask={false}
        size={'sideMenu'}
        customFullscreenCloseButton={true}
      >
        <React.Fragment>
          <Mask onClick={() => setShowModal(false)} />
          {children}
        </React.Fragment>
      </PortalModal>
    </React.Fragment>
  )
}
