import React, { ReactNode, createContext, useMemo } from 'react'

interface IEditUserModalHelpers {
  openSignatureModal: boolean
  openUserEditModal: boolean
  clickShowContentModal: boolean
  isLoading: boolean
  isOpenFromHeader: boolean
  phoneErrorMessage: string
  updatePerfilError: string
  updatePerfilSuccess: boolean
  classCodeValidation: boolean
  phone: string
  setIsOpenFromHeader: React.Dispatch<React.SetStateAction<boolean>>
  setOpenSignatureModal: React.Dispatch<React.SetStateAction<boolean>>
  setOpenUserEditModal: React.Dispatch<React.SetStateAction<boolean>>
  setClickShowContentModal: React.Dispatch<React.SetStateAction<boolean>>
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  setPhoneErrorMessage: React.Dispatch<React.SetStateAction<string>>
  setUpdatePerfilError: React.Dispatch<React.SetStateAction<string>>
  setUpdatePerfilSuccess: React.Dispatch<React.SetStateAction<boolean>>
  setClassCodeValidation: React.Dispatch<React.SetStateAction<boolean>>
  setPhone: React.Dispatch<React.SetStateAction<string>>
}

export interface IEditUserModalProps extends IEditUserModalHelpers {
  getOpenSignatureModal: (value: boolean) => void
  getOpenEditModal: (value: boolean) => void
}

interface IEditUserModalProviderProps {
  children: ReactNode
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const EditUserModalContext = createContext<IEditUserModalProps>({} as IEditUserModalProps)

const EditUserModalProvider: React.FC<IEditUserModalProviderProps> = ({ children }: IEditUserModalProviderProps) => {
  const [openUserEditModal, setOpenUserEditModal] = React.useState<boolean>(false)
  const [clickShowContentModal, setClickShowContentModal] = React.useState<boolean>(false)
  const [openSignatureModal, setOpenSignatureModal] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [phoneErrorMessage, setPhoneErrorMessage] = React.useState<string>('')
  const [updatePerfilError, setUpdatePerfilError] = React.useState<string>('')
  const [updatePerfilSuccess, setUpdatePerfilSuccess] = React.useState<boolean>(false)
  const [classCodeValidation, setClassCodeValidation] = React.useState<boolean>(false)
  const [phone, setPhone] = React.useState<string>('')
  const [isOpenFromHeader, setIsOpenFromHeader] = React.useState<boolean>(false)

  const getOpenEditModal = (value: boolean): void => {
    if (value) {
      setClickShowContentModal(true)
      setOpenUserEditModal(true)
      setOpenSignatureModal(false)
    }
  }

  const getOpenSignatureModal = (value: boolean): void => {
    if (value) {
      setOpenSignatureModal(true)
      setOpenUserEditModal(false)
    }
  }

  const userModal = useMemo(
    () => ({
      getOpenSignatureModal,
      openSignatureModal,
      setOpenSignatureModal,
      getOpenEditModal,
      openUserEditModal,
      setOpenUserEditModal,
      clickShowContentModal,
      setClickShowContentModal,
      isLoading,
      phoneErrorMessage,
      updatePerfilError,
      updatePerfilSuccess,
      classCodeValidation,
      setIsLoading,
      setPhoneErrorMessage,
      setUpdatePerfilError,
      setUpdatePerfilSuccess,
      setClassCodeValidation,
      setPhone,
      phone,
      isOpenFromHeader,
      setIsOpenFromHeader
    }),
    []
  )

  return <EditUserModalContext.Provider value={userModal}>{children}</EditUserModalContext.Provider>
}

const useEditUserModal = () => {
  return React.useContext(EditUserModalContext)
}

export { useEditUserModal, EditUserModalProvider, EditUserModalContext }
