import React from 'react'

const ViewActive = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-testid="view-active" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g id="Grupo_11412" data-name="Grupo 11412" transform="translate(0.063)">
      <g id="Grupo_9978" data-name="Grupo 9978">
        <rect id="Retângulo_3763" data-name="Retângulo 3763" width="40" height="40" transform="translate(-0.063)" fill="none"/>
      </g>
      <g id="Grupo_161711" data-name="Grupo 161711" transform="translate(-2075.063 -608)">
        <g id="Grupo_9978-2" data-name="Grupo 9978" transform="translate(1815 608)">
          <rect id="Retângulo_3763-2" data-name="Retângulo 3763" width="40" height="40" transform="translate(260)" fill="none"/>
        </g>
        <g id="Grupo_9620" data-name="Grupo 9620" transform="translate(1672.491 328.464)">
          <g id="Grupo_de_máscara_30" data-name="Grupo de máscara 30" transform="translate(413.372 292.185)">
            <g id="eye">
              <g id="Caminho_34" data-name="Caminho 34">
                <path id="Caminho_5455" data-name="Caminho 5455" d="M422.995,306.493c-6.043,0-9.417-6.6-9.559-6.88a.62.62,0,0,1,0-.549c.142-.281,3.513-6.88,9.559-6.88s9.417,6.6,9.557,6.88a.612.612,0,0,1,0,.549C432.412,299.889,429.038,306.493,422.995,306.493Zm-8.31-7.155c.685,1.2,3.679,5.929,8.31,5.929s7.626-4.726,8.309-5.928c-.685-1.2-3.678-5.929-8.309-5.929s-7.626,4.725-8.309,5.929Z" transform="translate(-413.372 -292.185)" fill="#2699fb"/>
              </g>
              <g id="Elipse_34" data-name="Elipse 34" transform="translate(6.557 4.088)">
                <path id="Caminho_5456" data-name="Caminho 5456" d="M421.661,301.581a3.067,3.067,0,1,1,3.067-3.067h0A3.07,3.07,0,0,1,421.661,301.581Zm0-4.906a1.84,1.84,0,1,0,1.84,1.84h0a1.84,1.84,0,0,0-1.84-1.835Z" transform="translate(-418.594 -295.448)" fill="#2699fb"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default ViewActive
