import React, { ReactElement } from 'react'
import { IconExporter, Button } from '@cockpit/zera'

import { Container, Text } from './styles'

interface Props {
  show: boolean
  showByProperty: 'visibility' | 'display'
  children: string
  margin?: string
  maxWidth?: string
  noCloseButton?: boolean
  onClose?: () => void
}

/**
 * Este componente irá renderizar uma mensagem de erro.
 * @param {boolean} show Necessário para exibir a mensagem de erro.
 * @param {'visibility' | 'display'} showByProperty Necessário para informar o tipo de exibição, *display* para retirar o componente inteiro em caso de `show` false e *visibility* para esconder o componente ainda assim ocupando espaço na tela.
 * @param {string} children Mensagem de error.
 * @param {string} margin (Opcional) Define uma margem, padrão é 0px.
 * @param {string} maxWidth (Opcional) Define um width para o componente, padrão é 100%.
 * @param {boolean} noCloseButton (Opcional) Retira a visibilidade do botão de fechar a mensagem.
 * @param {Function} onClose (Opcional) Função utilizada pelo botão de fechar a mensagem, necessário quando não for passado o parametro `noCloseButton`.
 *
 * O componente possui duas className.
 * *`container-error-message`* para o componente inteiro.
 * *`text-error-message`* para o texto da mensagem.
 */

export const ErrorMessage: React.FC<Props> = ({
  show,
  onClose,
  margin,
  maxWidth,
  children,
  showByProperty,
  noCloseButton = false
}): ReactElement => {
  return (
    <Container
      margin={margin}
      noCloseButton={noCloseButton}
      visible={show}
      showByProperty={showByProperty}
      maxWidth={maxWidth}
      className="container-error-message"
      data-testid="error-message"
    >
      <Text className="text-error-message">{show ? children : 'ShowByPropertyVisibility'}</Text>
      {!noCloseButton && (
        <Button onClick={onClose}>
          <IconExporter name="close" iconsize={9} />
        </Button>
      )}
    </Container>
  )
}
