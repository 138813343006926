import axios from 'axios'
import ENV from '../env'

axios.defaults.baseURL = ENV.API_BASE_URL

export const errorHandling = async (error: any) => {
  return Promise.reject(error.response || error.message)
}

export const setAxiosAuth = (token: string): void => {
  axios.defaults.headers.common.Authorization = token
}

export const removeAxiosAuth = async () => {
  delete axios.defaults?.headers?.common?.Authorization
}

export default axios
