import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  z-index: 10011;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #f6f7f9;
  opacity: 0.5;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  -webkit-animation: fade 0.3s forwards;
  -moz-animation: fade 0.3s forwards;
  animation: fade 0.3s forwards;

  @-webkit-keyframes fade {
    to {
      opacity: 1;
    }
  }

  @-moz-keyframes fade {
    to {
      opacity: 1;
    }
  }

  @keyframes fade {
    to {
      opacity: 1;
    }
  }
`

export const Animation = styled.div`
  display: flex;
  /* height: 58px; */
  margin-bottom: 30px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  svg {
    width: 250px;
    height: 40px;
  }

  h1 {
    text-align: center;
    white-space: pre-wrap;
    font-size: 1rem;
    font-weight: bold;
    color: #015697;
  }

  p {
    text-align: center;
    white-space: pre-wrap;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #015697;
  }
`
