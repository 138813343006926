import styled, { css } from 'styled-components'

interface ISelectPlat {
  show: boolean
  onlyHaveOnePlatformOrOffice?: boolean
}

interface IButtonPlat {
  active: boolean
}

export const Container = styled.div`
  position: relative;
`

export const LogoContainer = styled.button<ISelectPlat>`
  height: 64px;
  display: flex;
  align-items: center;
  border: 0px solid;
  background: transparent;
  padding: 0 15px;
  transition: all 0.2s ease;
  @media (min-width: 576px) {
    padding: 0 30px;
  }
  ${({ show, onlyHaveOnePlatformOrOffice }) =>
    onlyHaveOnePlatformOrOffice &&
    show &&
    css`
      background: rgba(0, 0, 0, 0.1);
    `}
  ${({ onlyHaveOnePlatformOrOffice }) =>
    onlyHaveOnePlatformOrOffice &&
    css`
      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
      cursor: pointer;
    `}
`

export const ArrowPlat = styled.div<ISelectPlat>`
  margin-left: 10px;
  transition: all 0.2s ease;
  transform: rotate(270deg);
  @media (min-width: 576px) {
    margin-left: 15px;
  }
  ${({ show }) =>
    show &&
    css`
      transform: rotate(90deg);
    `}
  svg * {
    stroke: rgba(0, 0, 0, 0.8);
  }
`

export const TitlePlatform = styled.div`
  font-family: Inter, sans-serif;
  font-weight: 600;
  font-size: 15px;
  padding-left: 10px;
  color: #122d52;
  margin-left: 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  @media (min-width: 576px) {
    font-size: 18px;
  }
`

export const ContainerSelectPlatformsOrOffices = styled.div<ISelectPlat>`
  position: absolute;
  left: 7px;
  top: calc(100% + 7px);
  display: none;
  flex-direction: column;
  padding: 15px 0;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  box-shadow: 0 8px 17px 2px rgb(0 0 0 / 14%);
  width: 250px;
  transition: all 0.2s ease;
  z-index: 1001;
  ${({ show }) =>
    !show &&
    css`
      opacity: 0;
      pointer-events: none;
      user-select: none;
    `}

  @media (min-width: 576px) {
    display: flex;
  }
`
export const ContainerOptions = styled.div`
  padding: 0 15px;
`

export const Mask = styled.div<ISelectPlat>`
  position: fixed;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 500;

  @media (max-width: 576px) {
    background-color: rgba(0, 0, 0, 0.5);
  }
`

export const SelectPlatMobile = styled.div<ISelectPlat>`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  ${({ show }) =>
    !show &&
    css`
      opacity: 0;
      pointer-events: none;
      user-select: none;
    `}

  @media (min-width: 576px) {
    display: none;
  }
`

export const ButtonCloseMobile = styled.button`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  border: 0px solid;
  font-size: 0px;
  background-color: transparent;
`

export const OptionsMobile = styled.div`
  background-color: #ffffff;
  /* color: #000000; */
  padding: 20px 30px 10px;
  z-index: 110;
`
export const ContainerPreferencesMobile = styled.div`
  background-color: #ffffff;
  padding: 0px 0px 20px;
  z-index: 110;
`
export const TitleMobile = styled.div`
  text-align: center;
  border-radius: 10px 10px 0 0;
  background-color: #ffffff;
  padding: 20px 30px;
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 110;
`

export const SelectPlatformOrOffice = styled.button<IButtonPlat>`
  width: 100%;
  height: 40px;
  border: 0px solid;
  text-align: left;
  background: transparent;
  cursor: pointer;
  color: #000 !important;
  transition: all 0.2s ease;
  border-radius: 5px;
  padding: 0 15px;
  font-family: Inter, sans-serif;
  font-weight: normal;
  font-size: 15px;
  display: flex;
  align-items: center;
  span {
    opacity: 0;
    background: rgba(0, 0, 0, 0.1);
    padding: 3px;
    border-radius: 100px;
    margin-right: 15px;
    color: rgba(0, 0, 0, 0.3) !important;
    transition: all 0.2s ease;
  }
  ${({ active }) =>
    active &&
    css`
      color: rgba(0, 0, 0, 0.3) !important;
      span {
        opacity: 1;
      }
    `}
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`
export const PreferencesOption = styled.div`
  text-align: center;
  padding: 5% 0 0;
  cursor: pointer;
`
