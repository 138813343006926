import React from 'react'

import { Container, Animation } from './styles'
import { ProgressBar } from '../Progress'
import Icons from '../../../assets/icons'

interface IPreloadingScreenProps {
  visible: boolean
  percentage?: number
}

export const PreloadingScreen: React.FC<IPreloadingScreenProps> = ({ visible, percentage }) => {
  return (
    <Container visible={visible}>
      <Animation>
        <Icons.LogoHstory />
      </Animation>
      <ProgressBar percentage={percentage} width="247px" />
    </Container>
  )
}
