import React, { useEffect, useState, ReactElement, useCallback } from 'react'
import {
  InputBox,
  FormContainer,
  LoginForm,
  LoginLogo,
  LoginDiv,
  LoginSection,
  Mask,
  StyledForm,
  Box,
  PasswordViewer
} from './styles'
import { useForm } from 'react-hook-form'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  login,
  selectSessionErrors,
  selectUserSession,
  selectLoading,
  selectCertification,
  selectAuthentication,
  setCertification,
  selectContextSSO,
  setLoginWithoutBirdId
} from '../../modules/login/sessionSlice'
import { PreloadingScreen } from '../../components/LoaderScreen/PreloadingScreen'
import { Button, IconExporter, Input, Tooltip } from '@cockpit/zera'
import { ErrorMessage } from '../../components/ErrorMessage'
import { LoginActiveDirectory } from '../../components/LoginModais/LoginActiveDirectory/'
import { IncorrectPasswordModal } from '../../components/LoginModais/IncorrectPasswordModal'
import { BlockedAccessModal } from '../../components/LoginModais/blockedAccessModal'

import { errorMessages } from './constants'
import Icons from '../../assets/icons'
import { SessionData, SessionError } from '../../modules/login/Session'
import { inputLoginId } from '../../enums'
import Footer from '../../components/Footer'
import useResize from '../../components/_layouts/useResize'
import { useSession, useContextSSO } from './hooks'
import { getSessionErrorKey, dynamicErrorMessage } from './functions'
import { withoutTheRouteParameter } from '../../shared/functions/sessionValidation'
import ENV from '../../modules/env'

interface Inputs {
  user: string
  password: string
}

export const Login: React.FC = (): ReactElement => {
  const { register, handleSubmit } = useForm<Inputs>()
  const dispatch = useDispatch()
  const sessionErrors: SessionError = useSelector(selectSessionErrors)
  const sessionData: SessionData | null = useSelector(selectUserSession)
  const loadingLogin = useSelector(selectLoading)
  const isContextSSO = useSelector(selectContextSSO)
  const sessionCert = useSelector(selectCertification)
  const authenticationData = useSelector(selectAuthentication)
  const [redirect, setRedirect] = useState<any>(null)
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)

  const [withoutBirdId, setWithoutBirdId] = useState(false)
  const [showAuthenticator, setShowAuthenticator] = useState(false)
  const [showLoginActiveDirectory, setShowLoginActiveDirectory] = useState(false)
  const [showDefaultPlatform, setShowDefaultPlatform] = useState(false)

  const windowSize = useResize()

  const [isInputFocus, setIsInputFocus] = useState<boolean>(false)
  const { isError, blockedAccess, incorrectPassword, isLoading, resetState } = useSession(loadingLogin, sessionErrors)

  const [isLoginSSO, queryParamsSSO] = useContextSSO()

  const isMobile = windowSize < 500

  const handleRedirectUserToPlatform = useCallback(
    (route?: string) => {
      setShowDefaultPlatform(false)
      // // if (((sessionCert && authenticationData?.access_token) ?? isLoginSSO) && !route) {
      //   return setRedirect(<Redirect to={withoutTheRouteParameter(sessionData, queryParamsSSO)} />)
      // // }

      // // if (((sessionCert && authenticationData?.access_token) ?? isLoginSSO) && route) {
      // //   return setRedirect(<Redirect to={route} />)
      // // }

      if ((sessionData ?? isLoginSSO) && !route) {
        return setRedirect(<Redirect to={withoutTheRouteParameter(sessionData, queryParamsSSO)} />)
      }

      if (sessionData && withoutBirdId && !route) {
        return setRedirect(<Redirect to={withoutTheRouteParameter(sessionData)} />)
      }
    },
    [sessionData, withoutBirdId, authenticationData, sessionCert, isLoginSSO, queryParamsSSO]
  )

  const onSubmit = (data: Inputs) => {
    resetState(['isError'])
    dispatch(login(data.user, data.password))
  }

  useEffect(() => {
    if (isLoginSSO) {
      setShowAuthenticator(false)
    } else {
      // TO-DO, refatorar lógica de login e diminuir complexidade
      if (sessionData && !showAuthenticator && !showLoginActiveDirectory && !showDefaultPlatform) {
        setShowAuthenticator(true)
      }

      if (sessionData) {
        setWithoutBirdId(true)
        dispatch(setLoginWithoutBirdId(sessionData))
      }

      if (authenticationData) {
        dispatch(setCertification(authenticationData.access_token))
      }
    }
  }, [
    sessionData,
    showAuthenticator,
    showLoginActiveDirectory,
    showDefaultPlatform,
    authenticationData,
    isLoginSSO,
    dispatch
  ])

  useEffect(() => {
    if (loadingLogin === 'success') {
      if (isLoginSSO) {
        handleRedirectUserToPlatform()
      }

      if (sessionData) {
        handleRedirectUserToPlatform()
      }
    }
  }, [loadingLogin, isLoginSSO, sessionData, handleRedirectUserToPlatform])

  return (
    <LoginSection>
      <PreloadingScreen visible={isContextSSO} />
      <Mask />
      {redirect}
      <LoginActiveDirectory
        showModal={showLoginActiveDirectory}
        onClose={() => {
          setShowLoginActiveDirectory(false)

          if ((sessionCert && authenticationData?.access_token) ?? isLoginSSO) {
            setShowDefaultPlatform(true)
          } else {
            handleRedirectUserToPlatform()
          }
        }}
      />
      <IncorrectPasswordModal showModal={incorrectPassword} onClose={() => resetState(['incorrectPassword'])} />
      <BlockedAccessModal
        showModal={blockedAccess}
        onClose={() => resetState(['blockedAccess'])}
        emailMasked={sessionErrors?.info?.email}
      />

      <FormContainer>
        <LoginDiv>
          <LoginLogo>
            <img alt="logo hospital albert einstein" src={`${ENV.IMAGES_URL}/images/logo_ae_branco.svg`} />
          </LoginLogo>
          <LoginForm>
            <ErrorMessage show={isError} showByProperty="visibility" margin="1rem 0" noCloseButton>
              {dynamicErrorMessage(sessionErrors?.info?.missing_attempts, sessionErrors?.error) ??
                errorMessages[getSessionErrorKey(sessionErrors)]}
            </ErrorMessage>
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
              <Box>
                <InputBox>
                  <Input
                    {...register('user', { required: true })}
                    onFocus={() => setIsInputFocus(true)}
                    onBlur={() => setIsInputFocus(false)}
                    placeholder="Usuário"
                    type="text"
                    id="usuario"
                    data-testid="user-login"
                    data-cy={inputLoginId.INPUT_USER_LOGIN}
                  />
                  <label htmlFor="usuario">
                    <Tooltip
                      description="Para realizar o login, utilize o nome de usuário cadastrado. Não é possível realizar login com o e-mail."
                      position={window.innerWidth >= 768 ? 'right' : 'left'}
                      opacity={0.9}
                      textMaxWidth={'250px'}
                    >
                      <IconExporter name="info_icon" iconsize={20} />
                    </Tooltip>
                  </label>
                </InputBox>
                <InputBox>
                  <Input
                    onCopy={(e: any) => {
                      e.preventDefault()
                      return false
                    }}
                    onFocus={() => setIsInputFocus(true)}
                    {...register('password', { required: true, onBlur: () => setIsInputFocus(false) })}
                    placeholder="Senha"
                    type={isPasswordVisible ? 'text' : 'password'}
                    margin={'5px 0 10px 0'}
                    data-testid="password-login"
                    data-cy={inputLoginId.INPUT_PASSWORD_LOGIN}
                  />
                  <PasswordViewer onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                    {isPasswordVisible ? <Icons.ViewActive /> : <Icons.View />}
                  </PasswordViewer>
                </InputBox>
              </Box>

              {/* TODO: resolver warning: index.js:1 Warning: Received `false` for a non-boolean attribute `loading`. */}
              <Button
                type="submit"
                isLoading={isLoading}
                data-testid="submit-login"
                data-cy={inputLoginId.INPUT_SUBMIT_LOGIN}
              >
                ENTRAR
              </Button>
            </StyledForm>
          </LoginForm>
        </LoginDiv>
        <Footer isFocused={isInputFocus && isMobile} />
      </FormContainer>
    </LoginSection>
  )
}
