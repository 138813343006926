import React from 'react'

const Import = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-testid="import-icon" id="import" xmlns="http://www.w3.org/2000/svg" width="16" height="14.614" viewBox="0 0 16 14.614">
    <g id="Retângulo_142423" data-name="Retângulo 142423" transform="translate(0 2.88)" fill="none" stroke="#a7abb2" stroke-width="1.3">
      <rect width="16" height="11.733" rx="2" stroke="none"/>
      <rect x="0.65" y="0.65" width="14.7" height="10.433" rx="1.35" fill="none"/>
    </g>
    <g id="Subtração_7" data-name="Subtração 7" transform="translate(1.133 0)" fill="#fff">
      <path d="M 0.6500002145767212 3.426791906356812 L 0.6500002145767212 1.999808311462402 C 0.6500002145767212 1.255528330802917 1.25592029094696 0.6500083208084106 2.000700235366821 0.6500083208084106 L 11.73509979248047 0.6500083208084106 C 12.47938060760498 0.6500083208084106 13.08489990234375 1.255528330802917 13.08489990234375 1.999808311462402 L 13.08489990234375 3.415308237075806 L 0.8316002488136292 3.415308237075806 L 0.6719702482223511 3.415308237075806 L 0.6500002145767212 3.426791906356812 Z" stroke="none"/>
      <path d="M 12.43490028381348 2.765308380126953 L 12.43490028381348 1.999808311462402 C 12.43490028381348 1.613938331604004 12.12096977233887 1.300008296966553 11.73509979248047 1.300008296966553 L 2.000700235366821 1.300008296966553 C 1.614330291748047 1.300008296966553 1.300000190734863 1.613938331604004 1.300000190734863 1.999808311462402 L 1.300000190734863 2.765308380126953 L 12.43490028381348 2.765308380126953 M 2.372741647604926e-07 4.499998092651367 L 2.372741647604926e-07 1.999808311462402 C 2.372741647604926e-07 0.8971183300018311 0.8975102305412292 8.331069693667814e-06 2.000700235366821 8.331069693667814e-06 L 11.73509979248047 8.331069693667814e-06 C 12.83779048919678 8.331069693667814e-06 13.73490047454834 0.8971183300018311 13.73490047454834 1.999808311462402 L 13.73490047454834 4.223148345947266 L 13.34339046478271 4.065308094024658 L 0.8316002488136292 4.065308094024658 L 2.372741647604926e-07 4.499998092651367 Z" stroke="none" fill="#a7abb2"/>
    </g>
    <line id="Linha_1290" data-name="Linha 1290" y2="5.333" transform="translate(8.241 6.029)" fill="none" stroke="#a7abb2" stroke-linecap="round" stroke-width="1.3"/>
    <line id="Linha_1291" data-name="Linha 1291" x1="5.333" transform="translate(5.574 8.747)" fill="none" stroke="#a7abb2" stroke-linecap="round" stroke-width="1.3"/>
  </svg>
)

export default Import
