import LocalStorageManager from '../../helpers/localStorage'
import { SessionData } from '../login/Session'

/**
 * Verifica se usuário tem acesso a plataforma do HSTORY
 */

export default function guardHstory(): void | boolean {
  const sessionData = LocalStorageManager.ReadEncryptedData<SessionData>('session') as SessionData
  if (sessionData) {
    return true
  }

  window.location.href = '/login'
}
