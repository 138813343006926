import React, { ReactElement } from 'react'
import { UserAvatar } from '@cockpit/zera'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import Icons from '../../assets/icons'
import { logoutThunk } from '../../modules/login/sessionSlice'
import { User } from '../../modules/login/User'
import { UserSidebarActions, UserSidebarContainer, UserSidebarHeader, IconContainer, UserSidebarAvatar } from './style'
import { useEditUserModal } from '../EditUserModal/context'
import removePrefixDoctor from '../../helpers/removePrefixDoctor'

export const UserSidebar: React.FC<{
  user: User
}> = ({ user }): ReactElement => {
  const dispatch = useDispatch()

  const { getOpenEditModal } = useEditUserModal()

  const logout = (): void => {
    dispatch(logoutThunk())
  }

  return (
    <UserSidebarContainer>
      <UserSidebarHeader>
        <UserSidebarAvatar>
          <UserAvatar name={removePrefixDoctor(user?.Objeto[0].NomeUsuario || '')} image={''} />
        </UserSidebarAvatar>
        <h4>{user?.Objeto[0].NomeUsuario}</h4>
        <span>{user.Objeto[0].DescEmail}</span>
      </UserSidebarHeader>

      <UserSidebarActions>
        <li>
          <a onClick={() => getOpenEditModal(true)}>
            <IconContainer>
              <span id="icon-edit">
                <Icons.Edit />
              </span>
            </IconContainer>
            <span>Informações do perfil</span>
          </a>
        </li>
        <li>
          <Link to="/login" onClick={logout}>
            <IconContainer>
              <span id="logout">
                <Icons.Logout stroke="currentColor" fill="currentColor" />
              </span>
            </IconContainer>
            <span>Sair</span>
          </Link>
        </li>
      </UserSidebarActions>
    </UserSidebarContainer>
  )
}
