import { theme } from '@cockpit/zera'
import styled, { css } from 'styled-components'

interface IPropsTitle {
  marginTop?: string
  marginBottom?: string
  marginRight?: string
  marginLeft?: string
  textTransform?: string
  fontWeight?: string
  fontSize?: string
  color?: string
}

interface IPropsUserEditModalStyle extends IPropsTitle {
  margin?: string
  color?: string
  displayNoneMobile?: string
}

interface IBorderActiveProps {
  isBorderActive: boolean
}

interface IOverflowHidden {
  overflowInitial: boolean
}

export const ContainerBody = styled.div`
  height: 100%;
  padding: 40px 20px;
`

export const ContainerModal = styled.div`
  > div {
    > div:nth-child(2) {
      overflow-y: initial;
    }
  }
`

export const ContainerContent = styled.div<IOverflowHidden>`
  height: 100%;
  overflow-y: auto;
  padding: 0px 20px;
  max-height: calc(100vh - 101px);
  ${({ overflowInitial }) =>
    overflowInitial &&
    css`
      overflow-y: initial;
    `}
  @media(min-width: 415px) {
    max-height: calc(100vh - 280px);
  }
  &::-webkit-scrollbar {
    width: 6px;
    transition: all 0.2s linear;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.5);
  }
`

export const WrapperContent = styled.div`
  max-height: 589px;
`

export const ContainerFlex = styled.div<IPropsUserEditModalStyle>`
  display: flex;
  position: fixed;
  justify-content: flex-start;
  padding: 24px 25px 0 25px;
  z-index: 10;
  position: relative;
  margin: ${({ margin }) => margin ?? 'unset'};

  & svg {
    position: absolute;
    z-index: 11;
    right: 20px;
    top: 24px;
    cursor: pointer;
  }
`
export const TitleForm = styled.p<IPropsUserEditModalStyle>`
  font-size: 13px;
  font-weight: ${theme.fontWeight.semibold};
  color: #2d3139;
  margin-top: ${theme.spacing.large};
  margin-bottom: ${theme.spacing.small};
  text-transform: uppercase;
`
export const TitleName = styled.h4`
  font-size: 20px;
  font-weight: ${theme.fontWeight.bold};
  color: #2d3139;
`

export const TabsTitle = styled.p`
  margin-bottom: 10%;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  @media (min-width: 415px) {
    font-size: 16px;
  }
`

export const BorderActiveColor = styled.div<IBorderActiveProps>`
  margin-right: 25px;
  color: ${theme.colors.gray4};
  border-bottom: ${({ isBorderActive }) => (isBorderActive ? `2px solid ${theme.colors.blue3}` : '')};
  > p {
    color: ${({ isBorderActive }) => (isBorderActive ? theme.colors.black : theme.colors.gray4)};
    font-weight: 600;
  }
`

export const CloseModal = styled.button`
  position: absolute;
  right: 25px;
  top: 25px;
  background-color: transparent;
  border: 0px solid;
  cursor: pointer;
  svg,
  svg * {
    fill: #c1c4cc;
  }
`
