import React, { useState } from 'react'
import { FooterModal as Modal, IconExporter } from '@cockpit/zera'
import { Container, FooterText, Header } from './styles'
import { message } from '../../../messages'
import Icons from '../../../assets/icons'
import { openPhoneNumber, openWhatsSupport } from '../common/functions'
import { CopyLink } from '../../../shared/CopyLink'
import { LinkCopiedToast } from '../../LinkCopiedToast'

interface IFooterModalProps {
  show: boolean
  onClose: () => void
}
export const FooterModal = ({ onClose, show }: IFooterModalProps) => {
  const [isLinkCopied, setIsLinkCopied] = useState(false)

  return (
    <Modal buttonHeight={'44px'} show={show} onClose={onClose}>
      <Container>
        <Header>{message('login.contactUs')}</Header>
        <button onClick={openWhatsSupport}>
          <IconExporter name="whats_icon" iconsize={18} />
          {message('login.whatsNumber')}*
        </button>
        <button onClick={openPhoneNumber}>
          <Icons.Phone />
          {message('login.phone')}*
        </button>
        <CopyLink link={message('login.email')} setIsLinkCopied={setIsLinkCopied}>
          <button>
            <IconExporter name="message_icon" iconsize={20} />
            {message('login.email')}
          </button>
        </CopyLink>
        <FooterText>{message('login.openingHours')}</FooterText>
      </Container>
      <LinkCopiedToast show={isLinkCopied} />
    </Modal>
  )
}
