import React from 'react'

const LogoCockpit = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-testid="logo-cockpit" xmlns="http://www.w3.org/2000/svg" width="35.247" height="40.819" viewBox="0 0 27.247 14.819">
    <g id="Grupo_163640" data-name="Grupo 163640" transform="translate(-1.555 -8.759)">
      <g id="Grupo_163538" data-name="Grupo 163538" transform="translate(-138.445 -170.242)">
        <path id="Caminho_27619" data-name="Caminho 27619" d="M11.945,16.39c-.027.107-.053.24-.08.347A2.985,2.985,0,0,1,9.655,18.9a6.309,6.309,0,0,1-1.731.24H7.871A6.309,6.309,0,0,1,6.14,18.9a3.028,3.028,0,0,1-2.21-2.159,7.652,7.652,0,0,1-.186-3.465,3.268,3.268,0,0,1,2.662-2.985,7.09,7.09,0,0,1,1.491-.16,7.281,7.281,0,0,1,1.491.16A3.2,3.2,0,0,1,12,13.005h3.168c-.053-.426-.133-.826-.213-1.253A5.128,5.128,0,0,0,10.8,7.6a13.447,13.447,0,0,0-2.9-.32A13.632,13.632,0,0,0,5,7.6,5.1,5.1,0,0,0,.842,11.753a12.886,12.886,0,0,0,.08,6.05,5,5,0,0,0,3.887,3.811,13.362,13.362,0,0,0,3.062.373,13.362,13.362,0,0,0,3.062-.373A4.967,4.967,0,0,0,14.82,17.8a12.055,12.055,0,0,0,.293-1.413Z" transform="translate(139.479 171.833)" fill="#051753"/>
        <path id="Caminho_27620" data-name="Caminho 27620" d="M143.751,21.576c-1.464-1.839-4.5-5.357-6.1-7.356a.637.637,0,0,1-.026-.906c1.384-1.812,4.713-6.29,4.713-6.29h-4.074l-6.15,8.076a.147.147,0,0,0,.107.24h1.757a1.16,1.16,0,0,1,.8.506c.772,1.013,4.127,5.064,4.659,5.757Z" transform="translate(23.495 171.977)" fill="#1c7cf9"/>
      </g>
    </g>
  </svg>
)

export default LogoCockpit
