import { message } from '../../../messages'

export const openWhatsSupport = () => {
  const url = 'https://api.whatsapp.com/send/?phone=551121513410'
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) {
    newWindow.opener = null
  }
}

export const openPhoneNumber = () => {
  const phoneNumber = `tel:${message('login.phone').replace(/\D+/g, '')}`
  window.open(phoneNumber, '_blank')
}
