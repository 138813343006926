import { SessionData } from '../login/Session'

const loadDynatraceReporter = (user: SessionData): void => {
  const missingUser = !user?.Objeto[0]
  if (missingUser) return
  const { CodLogin, NomeUsuario, DescEmail } = user?.Objeto[0]
  const userUUID = `${NomeUsuario} - ${CodLogin} - ${DescEmail}`
  ;(window as any).dtrum.identifyUser(userUUID)
}

export { loadDynatraceReporter }
