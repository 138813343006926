import styled from 'styled-components'

export const Container = styled.div<{ width?: string }>`
  width: ${({ width }) => width ?? '100%'};
  height: 5px;
  border-radius: 8px;
  overflow: hidden;
  background-color: #d9d9d9;
`
export const Fill = styled.div<{ percentage?: number }>`
  width: ${({ percentage }) => `${percentage}%`};
  transition: width 0.3s;
  height: 100%;
  border-radius: 8px;
  background-color: #015697;
`
