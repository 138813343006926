import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import sessionSlice, { authenticationSlice, permissionsSlice } from '../login/sessionSlice'
import { selectPlatformSlice } from '../session/sessionSlice'

export const store = configureStore({
  reducer: {
    session: sessionSlice,
    authentication: authenticationSlice.reducer,
    selectPlatform: selectPlatformSlice.reducer,
    permissions: permissionsSlice.reducer
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

export default store
