import React, { createContext, ReactNode } from 'react'
import { Inputs } from '../../components/SecurityEditModal/SecurityEditModal'
import { useEditUserModal } from '../EditUserModal/EditUserModalContext'

interface ISecurityEditModalHelpers {
  isLoading: boolean
  password: string
  confirmPassword: string
  minLengthValidation: boolean
  upperCaseValidation: boolean
  lowerCaseValidation: boolean
  numberValidation: boolean
  specialCharacterValidation: boolean
  confirmPasswordValidation: boolean
  updatePasswordError: boolean
  updatePasswordSuccess: boolean
  isButtonDisabled: boolean
  isCurrentPasswordEmpty: boolean
  isPasswordVisible: boolean
  isConfirmPasswordVisible: boolean
  isCurrentPasswordVisible: boolean
  onlyDefaultPlatformChanged: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  setPassword: React.Dispatch<React.SetStateAction<string>>
  setConfirmPassword: React.Dispatch<React.SetStateAction<string>>
  setMinLengthValidation: React.Dispatch<React.SetStateAction<boolean>>
  setUpperCaseValidation: React.Dispatch<React.SetStateAction<boolean>>
  setNumberValidation: React.Dispatch<React.SetStateAction<boolean>>
  setSpecialCharacterValidation: React.Dispatch<React.SetStateAction<boolean>>
  setConfirmPasswordValidation: React.Dispatch<React.SetStateAction<boolean>>
  setUpdatePasswordError: React.Dispatch<React.SetStateAction<boolean>>
  setUpdatePasswordSuccess: React.Dispatch<React.SetStateAction<boolean>>
  setIsButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>
  setIsCurrentPasswordEmpty: React.Dispatch<React.SetStateAction<boolean>>
  setIsPasswordVisible: React.Dispatch<React.SetStateAction<boolean>>
  setIsConfirmPasswordVisible: React.Dispatch<React.SetStateAction<boolean>>
  setIsCurrentPasswordVisible: React.Dispatch<React.SetStateAction<boolean>>
  setOnlyDefaultPlatformChanged: React.Dispatch<React.SetStateAction<boolean>>
}

export interface ISecurityModalProps extends ISecurityEditModalHelpers {
  onSubmitSecurityEdit: (data: Inputs) => Promise<void>
}

interface ISecurityEditModalProviderProps {
  children: ReactNode
}
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const SecurityEditModalContext = createContext<ISecurityModalProps>({} as ISecurityModalProps)

const SecurityEditModalProvider: React.FC<ISecurityEditModalProviderProps> = ({
  children
}: ISecurityEditModalProviderProps) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [password, setPassword] = React.useState<string>('')
  const [confirmPassword, setConfirmPassword] = React.useState<string>('')
  const [minLengthValidation, setMinLengthValidation] = React.useState<boolean>(false)
  const [upperCaseValidation, setUpperCaseValidation] = React.useState<boolean>(false)
  const [lowerCaseValidation, setLowerCaseValidation] = React.useState<boolean>(false)
  const [numberValidation, setNumberValidation] = React.useState<boolean>(false)
  const [specialCharacterValidation, setSpecialCharacterValidation] = React.useState<boolean>(false)
  const [confirmPasswordValidation, setConfirmPasswordValidation] = React.useState<boolean>(false)
  const [updatePasswordError, setUpdatePasswordError] = React.useState<boolean>(false)
  const [updatePasswordSuccess, setUpdatePasswordSuccess] = React.useState<boolean>(false)
  const [isButtonDisabled, setIsButtonDisabled] = React.useState<boolean>(true)
  const [isCurrentPasswordEmpty, setIsCurrentPasswordEmpty] = React.useState<boolean>(false)
  const [isPasswordVisible, setIsPasswordVisible] = React.useState<boolean>(false)
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = React.useState<boolean>(false)
  const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] = React.useState<boolean>(false)
  const [onlyDefaultPlatformChanged, setOnlyDefaultPlatformChanged] = React.useState<boolean>(false)

  const { setOpenUserEditModal } = useEditUserModal()

  React.useEffect(() => {
    setMinLengthValidation(password.length >= 8)
    setUpperCaseValidation(password.toLocaleLowerCase() !== password)
    setLowerCaseValidation(password.toLocaleUpperCase() !== password)
    setNumberValidation(!!password.match(/\d+/g))
    setSpecialCharacterValidation(!!password.match(/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/))
  }, [password])

  React.useEffect(() => {
    if (password && confirmPassword === '') {
      setConfirmPasswordValidation(true)
      return
    }

    setConfirmPasswordValidation(password === confirmPassword)
  }, [password, confirmPassword])

  React.useEffect(() => {
    setIsButtonDisabled(
      !!(
        !minLengthValidation ||
        !upperCaseValidation ||
        !lowerCaseValidation ||
        !numberValidation ||
        !specialCharacterValidation ||
        !confirmPasswordValidation
      )
    )
  }, [
    minLengthValidation,
    upperCaseValidation,
    lowerCaseValidation,
    numberValidation,
    specialCharacterValidation,
    confirmPasswordValidation
  ])

  const onSubmitSecurityEdit = async (data: Inputs) => {
    try {
      setIsLoading(true)

      setUpdatePasswordSuccess(true)
      setTimeout(() => {
        setOpenUserEditModal(false)
      }, 4000)
    } catch (e: any) {
      setIsLoading(false)
      setUpdatePasswordError(true)
      setUpdatePasswordSuccess(false)
    } finally {
      setTimeout(() => {
        setIsLoading(false)
      }, 800)
    }
  }

  return (
    <SecurityEditModalContext.Provider
      value={{
        isLoading,
        password,
        confirmPassword,
        minLengthValidation,
        upperCaseValidation,
        lowerCaseValidation,
        numberValidation,
        specialCharacterValidation,
        confirmPasswordValidation,
        updatePasswordError,
        updatePasswordSuccess,
        isButtonDisabled,
        isCurrentPasswordEmpty,
        isPasswordVisible,
        isConfirmPasswordVisible,
        isCurrentPasswordVisible,
        onlyDefaultPlatformChanged,
        setIsLoading,
        setPassword,
        setConfirmPassword,
        setMinLengthValidation,
        setUpperCaseValidation,
        setNumberValidation,
        setSpecialCharacterValidation,
        setConfirmPasswordValidation,
        setUpdatePasswordError,
        setUpdatePasswordSuccess,
        setIsButtonDisabled,
        setIsCurrentPasswordEmpty,
        setIsPasswordVisible,
        setIsConfirmPasswordVisible,
        setIsCurrentPasswordVisible,
        onSubmitSecurityEdit,
        setOnlyDefaultPlatformChanged
      }}
    >
      {children}
    </SecurityEditModalContext.Provider>
  )
}
const useSecurityEditModal = () => {
  return React.useContext(SecurityEditModalContext)
}

export { SecurityEditModalContext, useSecurityEditModal, SecurityEditModalProvider }
