import React, { useEffect } from 'react'
import useResize from '../../components/_layouts/useResize'
import { Container, Content, IconContainer, Mask, ModalContent, ModalHeader, ModalTitle } from './styles'
import { IconExporter } from '@cockpit/zera'

/**
 * Modal responsiva que se auto ajusta conforme a tela
 * @param {boolean} show Propriedade para mostrar a modal
 * @param {Function} onClose Função chamada ao clicar no botão de fechar modal
 * @param {React.ReactNode} children Elemento filho do conteúdo do modal
 * @param {string} height (Opcional) Propriedade para ajustar altura do conteúdo da modal
 * @param {string} width (Opciona) Propriedade para ajustar largura do conteúdo da modal
 * @param {string} maxW (Opcional) Propriedade para ajustar a largura maxima do conteúdo da modal
 * @param {string} padding (Opcional) Propriedade para ajustar o espaçamento interno do conteúdo da modal
 * @param {string} title (Opcional) Propriedade para adicionar um título ao conteúdo do modal
 * @param {rollback | close} modalMode (Opcional) Propriedade para ajustar qual icone será utilizado na modal mobile
 * @param {boolean} closeOnClickMask (Opcional) Propriedade para quando clicar na mascará a modal chamar o onClose
 * @param {boolean} hideCloseIcon (Opcional) Propriedade para esconder o icone de fechar do conteúdo da modal
 * @param {string} backgroundColor (Opcional) Propriedade para escolher a cor de fundo do conteúdo da modal
 */

export interface ResponsiveModalProps {
  show: boolean
  onClose: () => void
  children: React.ReactNode
  height?: string
  width?: string
  maxW?: string
  padding?: string
  title?: string
  modalMode?: 'rollback' | 'close'
  closeOnClickMask?: boolean
  hideCloseIcon?: boolean
  backgroundColor?: string
}

export const ResponsiveModal = ({
  show,
  onClose,
  children,
  height,
  width,
  maxW,
  title,
  modalMode = 'rollback',
  padding,
  closeOnClickMask = true,
  hideCloseIcon = false,
  backgroundColor
}: ResponsiveModalProps) => {
  const windowSize = useResize()

  useEffect(() => {
    if (show) {
      document.querySelector('body')?.setAttribute('style', 'overflow:hidden')
    } else if (document.getElementsByClassName('modal-active').length === 0) {
      document.querySelector('body')?.removeAttribute('style')
    }
  }, [show])

  return (
    <Container show={show} className={`modal-${show ? 'active' : 'inactive'}`} data-testid="modal-edit-desktop">
      <Mask data-testid='close-button' onClick={closeOnClickMask ? onClose : () => {}} />
      <ModalContent backgroundColor={backgroundColor} padding={'16px'} height={height} width={width} maxW={maxW}>
        <>
          {windowSize <= 500 && (
            <ModalHeader backgroundColor={backgroundColor} modalMode={modalMode} hideCloseIcon={!hideCloseIcon}>
              {modalMode === 'rollback' && !hideCloseIcon && (
                <IconExporter onClick={onClose} name="chevron" width="14px" />
              )}
              <ModalTitle>{title}</ModalTitle>
              {modalMode === 'close' && <IconExporter onClick={onClose} name="close" width="14px" />}
            </ModalHeader>
          )}
          {windowSize > 500 && !hideCloseIcon && (
            <IconContainer>
              <IconExporter name="close" width="16px" onClick={onClose} />
            </IconContainer>
          )}
          {show && <Content padding={padding}>{children}</Content>}
        </>
      </ModalContent>
    </Container>
  )
}
