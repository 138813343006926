import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { User } from '../../modules/login/User'
import { SessionData } from '../../modules/login/Session'
import { selectUserSession } from '../../modules/login/sessionSlice'
import UserMenu from './UserMenu/UserMenu'

const UserMenuContainer: React.FC<{}> = (): ReactElement | null => {
  const userSession: SessionData | null = useSelector(selectUserSession)
  const user: User | null = userSession ?? null

  return <UserMenu user={user}></UserMenu>
}

export default UserMenuContainer
