import React from 'react'

const Camera = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="Iconly_Light-outline_Camera"
    data-testid="camera-icon"
    data-name="Iconly/Light-outline/Camera"
    xmlns="http://www.w3.org/2000/svg"
    width="12.059"
    height="10.809"
    viewBox="0 0 12.059 10.809"
  >
    <g id="Camera">
      <path
        id="Camera-2"
        data-name="Camera"
        d="M2.631,10.809A2.626,2.626,0,0,1,0,8.192V4.631A2.611,2.611,0,0,1,2.6,2.016l.047,0a.13.13,0,0,0,.1-.072l.3-.623L3.275.846A1.461,1.461,0,0,1,4.61,0H7.448A1.462,1.462,0,0,1,8.784.845c.039.076.085.169.142.284l.387.813a.136.136,0,0,0,.128.073l.128,0a2.621,2.621,0,0,1,2.491,2.613V8.192a2.626,2.626,0,0,1-2.63,2.617Zm1.45-9.553c-.036.071-.081.161-.135.272l-.382.8a1.04,1.04,0,0,1-.831.582l-.1.006A1.723,1.723,0,0,0,.9,4.633V8.194A1.723,1.723,0,0,0,2.631,9.91h6.8a1.722,1.722,0,0,0,1.725-1.716V4.633A1.713,1.713,0,0,0,9.532,2.919l-.2-.007A1.033,1.033,0,0,1,8.5,2.328l-.422-.879-.1-.194A.558.558,0,0,0,7.445.9H4.617A.564.564,0,0,0,4.081,1.256ZM6.03,8.561a2.358,2.358,0,0,1-1.674-.693,2.347,2.347,0,0,1-.01-3.326,2.36,2.36,0,0,1,1.683-.7l.125,0a2.359,2.359,0,0,1-.123,4.716ZM5.935,4.745A1.474,1.474,0,0,0,4.565,6.2a1.466,1.466,0,0,0,1.464,1.46A1.465,1.465,0,0,0,7.494,6.2,1.466,1.466,0,0,0,6.031,4.741Zm3.05.053a.654.654,0,0,1-.18-.048.6.6,0,0,1-.194-.132.61.61,0,0,1-.17-.417.587.587,0,0,1,.049-.236.616.616,0,0,1,.142-.206.683.683,0,0,1,.184-.117.618.618,0,0,1,.652.13.585.585,0,0,1,.115.157l.014.035a.587.587,0,0,1,.049.238.6.6,0,0,1-.543.6l-.06,0Z"
        fill="#fff"
      />
    </g>
  </svg>
)

export default Camera
