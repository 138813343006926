import ENV from '../modules/env'
import { NewURL } from '../utils'

const platformRoute = [{ id: ENV.HSTORY_PLATFORM_ID, route: '/dashboard' }]

const defaultRoute = {
  id: ENV.HSTORY_PLATFORM_ID,
  route: '/dashboard'
}

interface LoginSSO {
  patientEpisodeNumber?: string
  target?: string | null
}

type GetPlatformRoute = (id: number | null | string, loginSSO?: LoginSSO) => string

export const getPlatformRoute: GetPlatformRoute = (id, loginSSO) => {
  const filter = platformRoute.filter(element => element.id === id)

  const route = filter[0]?.route || defaultRoute.route

  if (loginSSO && id === ENV.HSTORY_PLATFORM_ID) {
    const defaultPathLoginSSO = new NewURL(`${route}/pesquisar-paciente`)

    if (loginSSO.patientEpisodeNumber) {
      defaultPathLoginSSO.setQueryParams('episodeNumber', loginSSO.patientEpisodeNumber)
    }

    return defaultPathLoginSSO.toString()
  }

  return route
}

export const getPlatformIdByRoute = (route: string) => {
  const id = platformRoute.filter(element => element?.route === route)[0]?.id
  if (!id) return
  return Number(id) || defaultRoute.id
}

export const getOfficeRoute = '/consultorio'
